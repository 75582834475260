import {InquiryDetailsComponent} from './inquiry-details.component';
import {InquirySearchComponent} from './inquiry-search.component';
import {OfferDetailsComponent} from './offer-details.component';
import {InquiryDetailsGuard, InquiryOfferDetailsGuard} from './_guards';

export const InquiryRoutes = [
  {
    path: 'inquiry-details/:id',
    component: InquiryDetailsComponent,
    canActivate: [InquiryDetailsGuard],
  },
  {
    path: 'inquiry-details/:id/:clientId',
    component: InquiryDetailsComponent,
    canActivate: [InquiryDetailsGuard],
  },
  {
    path: 'inquiry-details/:id/:clientId/:typeId/:buId',
    component: InquiryDetailsComponent,
    canActivate: [InquiryDetailsGuard],
  },
  {
    path: 'inquiry-details-newclient/:readFromCache',
    component: InquiryDetailsComponent,
  },
  {
    path: 'inquiry-details-subinsured/:readFromCache',
    component: InquiryDetailsComponent,
  },
  {
    path: 'inquiry-search',
    component: InquirySearchComponent,
  },
  {
    path: 'inquiry-offer-details/:inquiryId/offer/:offerId',
    component: OfferDetailsComponent,
    canActivate: [InquiryOfferDetailsGuard],
  },
  {
    path: 'inquiry-offer-details/:inquiryId/newoffer/:offerTypeId',
    component: OfferDetailsComponent,
    canActivate: [InquiryOfferDetailsGuard],
  },
  {
    path: 'inquiry-offer-details/:inquiryId/newoffer/:offerTypeId/sourceoffer/:sourceOfferId',
    component: OfferDetailsComponent,
    canActivate: [InquiryOfferDetailsGuard],
  },
  {
    path: 'inquiry-offer-details/:inquiryId/newoffer/:offerTypeId/sourceoffer/:sourceOfferId/:blogOffer',
    component: OfferDetailsComponent,
    canActivate: [InquiryOfferDetailsGuard],
  },
];
