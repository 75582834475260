<details-view [title]="'Offer'" [objectName]="'Inquiry'" [parentComponent]="self" [versionListHidden]="true"
  [createRightOrFunc]="createRightFunc" [updateRightOrFunc]="updateRightFunc" [object]="offerEnvelope.offer" modelObjectName="offer">
  <div class="details-content">
    <div class="bon-label-190">
      <form #ngForm="ngForm">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <company-info [company]='offerEnvelope.client' [showLink]="false"></company-info>
          </div>
          <div class="bon-card-inner">
            <textarea-row [(ngModel)]="offerEnvelope.offer.comment" labelKey="common.comment" rows='3' [disabled]="!canSaveBlog()"
                          [minlength]="3" [showErrors]="showErrors" name="comment">
            </textarea-row>
          </div>
        </div>
        <div class="bon-card-group">
        </div>
        <bon-section [title]="getOfferTypeLabel()">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <div *ngIf="offerEnvelope.binding === true || offerEnvelope.binding === false" class="bon-row">
                <label class="bon-label" translate>offer.binding</label>
                <checkbox-entity [ngModel]="offerEnvelope.binding" name="bindingOfferCheckbox"
                                 [presentationMode]="true" style="width: auto; min-width: 0; margin-top: 0px">
                </checkbox-entity>
              </div>
              <div class="bon-row">
                <label class="bon-label" translate>offer.blogOffer</label>
                <checkbox-entity [ngModel]="offerEnvelope.offer.blogOfferSource" name="blogOfferCheckbox"
                                 [presentationMode]="true" style="width: auto; min-width: 0; margin-top: 0">
                </checkbox-entity>
                <div *ngIf="offerEnvelope.offer.blogOfferSource; then blogOfferBlock else notBlogOfferBlock"></div>
                <ng-template #blogOfferBlock>&nbsp;<span style="display: inline-block; height: 15px; vertical-align: bottom;">
                  {{'offer.blogOnOffer' | translate}}
                  <a class="a-link" (click)="goToOffer(offerEnvelope.offer.blogOfferSource.id)">
                    {{offerEnvelope.offer.blogOfferSource.number}}
                  </a> </span>
                </ng-template>
                <ng-template #notBlogOfferBlock>&nbsp;</ng-template>
              </div>
              <div class="bon-row">
                <label class="bon-label">{{'Status' | translate}}</label>
                <dict-combo class="bon-input-size" [ngModel]="offerEnvelope.offer.status" dictionary="PolicyInquiryOfferStatus"
                            [disabled]="true" [ngModelOptions]="{standalone: true}"></dict-combo>
              </div>
              <num-row labelKey="offer.insurancePeriod" [formControl]="form.controls.insurancePeriod" name="insurancePeriod"
                       [control]="form.controls.insurancePeriod" [disabled]="!canSave()" [showErrors]="showErrors" type="integer"></num-row>
              <num-row *ngIf="isSectionVisible(viewSection.OFFER__INSURANCE_COVER)" labelKey="offer.insuranceCover"
                       [formControl]="form.controls.insuranceCover" [control]="form.controls.insuranceCover" name="insuranceCover"
                       [disabled]="!canSave()" [showErrors]="showErrors" [numberOfDecimalPlaces]="5"></num-row>
              <num-row *ngIf="isSectionVisible(viewSection.OFFER__NQL)" label="NQL" [formControl]="form.controls.nql" name="nql"
                       [control]="form.controls.nql" [disabled]="!canSave()" [showErrors]="showErrors"></num-row>
              <num-row *ngIf="form.controls.aad" label="AAD" [formControl]="form.controls.aad" name="aad"
                       [control]="form.controls.aad" [disabled]="!canSave()" [showErrors]="showErrors"></num-row>
              <num-row *ngIf="form.controls.globalLOL" labelKey="offer.globalLOL"
                       [formControl]="form.controls.globalLOL" name="globalLOL" [control]="form.controls.globalLOL"
                       [showErrors]="showErrors" (changeValue)="form.get('annualLOL').updateValueAndValidity()"
                       [disabled]="!canSave()"></num-row>
              <num-row label="Annual LOL" [formControl]="form.controls.annualLOL" name="annualLOL"
                       [control]="form.controls.annualLOL" [disabled]="!canSave()" [showErrors]="showErrors"></num-row>
              <num-row *ngIf="isSectionVisible(viewSection.OFFER__FOCUS_PREMIUM)" label="Focus premium" [(ngModel)]="offerEnvelope.offer.focusPremium" name="focusPremium"
                       [disabled]="!canSave()" [showErrors]="showErrors"></num-row>
              <checkbox-row *ngIf="isSectionVisible(viewSection.OFFER__PD)" label="PD" name="pd" [(ngModel)]="offerEnvelope.offer.pd"
                            [disabled]="!canSave()" [showErrors]="showErrors"></checkbox-row>
              <checkbox-row label="GRC" name="grc" [(ngModel)]="offerEnvelope.offer.grc" (changeState)="grcChanged($event)"
                       [disabled]="!canSave()" [showErrors]="showErrors"></checkbox-row>
              <date-row *ngIf="offerEnvelope.offer.grc" label="GRC Date" [(ngModel)]="offerEnvelope.offer.grcDate" name="grcDate"
                        [disabled]="!canSave()" [showErrors]="showErrors"></date-row>
            </div>
            <div class="bon-card-inner">
              <div class="bon-row">
                &nbsp;
              </div>
              <ng-container>
                <num-row labelKey="offer.premium" [formControl]="form.controls.premium" [control]="form.controls.premium" [disabled]="!canSave()"
                         [showErrors]="showErrors" name="premium" (changeValue)="form.get('invoicedPremium').updateValueAndValidity()"></num-row>
                <num-row label="Premium rate (%)" [formControl]="form.controls.premiumRate" [disabled]="!canSave()"
                         name="premiumRate" [control]="form.controls.premiumRate" [showErrors]="showErrors" numberOfDecimalPlaces="4"></num-row>
                <num-row labelKey="offer.invoicedPremium" [formControl]="form.controls.invoicedPremium"
                         [control]="form.controls.invoicedPremium" [disabled]="!canSave()"
                         [showErrors]="showErrors" name="invoicedPremium"></num-row>
              </ng-container>
              <num-row *ngIf="isSectionVisible(viewSection.OFFER__MIN_RETENTION)" label="Minimum retention" [(ngModel)]="offerEnvelope.offer.minRetention" name="minRetention"
                       [disabled]="!canSave()" [showErrors]="showErrors"></num-row>
              <num-row *ngIf="isSectionVisible(viewSection.OFFER__MIN_RETENTION)" label="Minimum retention (%)" [(ngModel)]="offerEnvelope.offer.minRetentionPercent" name="minRetentionPercent"
                       [disabled]="!canSave()" [showErrors]="showErrors"></num-row>
              <num-row *ngIf="isSectionVisible(viewSection.OFFER__EE)" label="E&E" [(ngModel)]="offerEnvelope.offer.ee" name="ee"
                       [disabled]="!canSave()" [showErrors]="showErrors"></num-row>
              <num-row labelKey="offer.numberOfInstalments" [(ngModel)]="offerEnvelope.offer.numberOfInstalments" name="numberOfInstalments"
                       [disabled]="!canSave()" [showErrors]="showErrors" type="integer"></num-row>
              <num-row *ngIf="isSectionVisible(viewSection.OFFER__PROTRACTED_DEFAULT)" labelKey="offer.protractedDefault"
                       [(ngModel)]="offerEnvelope.offer.protractedDefault" name="protractedDefault"
                       [disabled]="!canSave()" [showErrors]="showErrors" type="integer"></num-row>
              <div *ngIf="isSectionVisible(viewSection.OFFER__RISK_ASSESSMENT)" class="bon-row">
                <label class="bon-label">Risk assessment</label>
                <dict-combo class="bon-input-size" [(ngModel)]="offerEnvelope.offer.riskAssessment"
                            name="riskAssessmentType" dictionary="RiskAssessmentType" [disabled]="!canSave()"></dict-combo>
              </div>
              <num-row *ngIf="isSectionVisible(viewSection.OFFER__ROL)" label="ROL" name="rol" disabled="true"
                       [ngModel]="offerEnvelope.offer.premium / (offerEnvelope.offer.annualLOL || offerEnvelope.offer.globalLOL)">
              </num-row>
              <ng-container *ngIf="isSectionVisible(viewSection.PREMIUM__SR_DETAILS)">
                <dict-row dictionary="PremiumType" [(ngModel)]="offerEnvelope.offer.premiumType"
                          labelKey="offer.details.premiumType" name="premiumType"></dict-row>
                <dict-row dictionary="PremiumPayer" [(ngModel)]="offerEnvelope.offer.premiumPayer"
                          labelKey="offer.details.premiumPayer" name="premiumPayer"></dict-row>
                <dict-row dictionary="PremiumFrequency" [(ngModel)]="offerEnvelope.offer.premiumFrequency"
                          labelKey="offer.details.premiumFrequency" name="premiumFrequency"></dict-row>
                <dict-row dictionary="PremiumCalculationMethod" [(ngModel)]="offerEnvelope.offer.premiumCalculationMethod"
                          labelKey="offer.details.premiumCalculationMethod" name="premiumCalculationMethod"></dict-row>
              </ng-container>
            </div>
          </div>
        </bon-section>

        <bon-section [labelKey]="bonusMalusSectionLabelKey" *ngIf="isSectionVisible(viewSection.OFFER__DISCRETIONARY_COVER_BONUS_MALUS)">
          <div class="bon-card-group">
            <div class="bon-card-inner" *ngIf="isSectionVisible(viewSection.OFFER__DISCRETIONARY_COVER)">
              <checkbox-row labelKey="offer.details.discretionaryCoverBonusMalus.discretionaryCover" name="discretionaryCover"
                            [(ngModel)]="offerEnvelope.offer.discretionaryCover" [disabled]="!canSave()"
                            (changeState)="discretionaryCoverChanged($event)"></checkbox-row>
              <num-row labelKey="offer.details.discretionaryCoverBonusMalus.maxPaymentTermDays" [disabled]="!canSave() || !offerEnvelope.offer.discretionaryCover" type="integer"
                       [(ngModel)]="offerEnvelope.offer.dcMaxPaymentTermDays" name="dcMaxPaymentTermDays"></num-row>
              <num-row labelKey="offer.details.discretionaryCoverBonusMalus.maxLimit" [disabled]="!canSave() || !offerEnvelope.offer.discretionaryCover"
                       [(ngModel)]="offerEnvelope.offer.dcMaxLimit" name="dcMaxLimit"></num-row>
              <num-row labelKey="offer.details.discretionaryCoverBonusMalus.insuranceCoverPercent" [disabled]="!canSave() || !offerEnvelope.offer.discretionaryCover"
                       [(ngModel)]="offerEnvelope.offer.dcInsuranceCoverPercent" name="dcInsuranceCoverPercent" [min]="0" [max]="100"></num-row>
              <num-row labelKey="offer.details.discretionaryCoverBonusMalus.insuredAmount" [disabled]="!canSave() || !offerEnvelope.offer.discretionaryCover"
                       [(ngModel)]="offerEnvelope.offer.dcInsuredAmount" name="dcInsuredAmount"></num-row>
            </div>
            <div class="bon-card-inner">
              <div class="bon-header-inner" translate="policy.details.bonusMalusSection.bonuses"></div>
              <div class="bon-row">
                <a-table [items]="offerEnvelope.offer.bonuses" [deleteButton]="canSave()" [editable]="canSave()">
                  <column cellWidth="50%" property="lossRatio" labelKey="policy.details.bonusMalusSection.lossRatioLessOrEqual" type="number"></column>
                  <column cellWidth="50%" property="premiumPayback" labelKey="policy.details.bonusMalusSection.premiumPaybackPercent" type="number"></column>
                </a-table>
              </div>
            </div>

            <div class="bon-card-inner">
              <div class="bon-header-inner" translate="policy.details.bonusMalusSection.maluses"></div>
              <div class="bon-row">
                <a-table [items]="offerEnvelope.offer.maluses" [deleteButton]="canSave()" [editable]="canSave()">
                  <column cellWidth="50%" property="lossRatio" labelKey="policy.details.bonusMalusSection.lossRatioGreaterThan" type="number"></column>
                  <column cellWidth="50%" property="premiumIncrease" labelKey="policy.details.bonusMalusSection.premiumIncreasePercent" type="number"></column>
                </a-table>
              </div>
            </div>
          </div>
        </bon-section>

        <bon-section *ngIf="isSectionVisible(viewSection.OFFER__SCOPE_OF_COVER)" labelKey="policy.details.scopeSection.title">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <boolean-pair-radio-row [inputValue]="{left: this.offerEnvelope.offer.politicalRisk, right: this.offerEnvelope.offer.commercialRisk}"
                  leftLabelKey="policy.details.scopeSection.politicalRiskOnly" rightLabelKey="policy.details.scopeSection.commercialRiskOnly"
                  bothLabelKey="policy.details.scopeSection.politicalAndCommercialRisk" labelKey="policy.details.scopeSection.riskOptions" [hideNone]="true"
                  name="selectedRiskOption" (changeItem)="onRiskOptionChanged($event)" [mapNoneToBoth]="true" [disabled]="!isEditable()"></boolean-pair-radio-row>
            </div>
            <div class="bon-card-inner">
              <num-row [(ngModel)]="offerEnvelope.offer.deliveryStopDays" type="integer" [presentationMode]="!isEditable()"
                  name="deliveryStopDays" labelKey="policy.details.otherSection.deliveryStopDays" required [showErrors]="showErrors"></num-row>
            </div>
            <div class="bon-card-inner"></div>
          </div>
        </bon-section>

        <bon-section [title]="'offer.voting.title' | translate">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <div class="bon-row">
                <label class="bon-label">{{'Status' | translate}}</label>
                <span class="bon-input" translate>{{offerEnvelope.offer.votingStatus?.name}}
                  <i class="fa"  [ngClass]="{'fa-thumbs-o-up vote-up' : votingStatusAccepted(), 'fa-thumbs-o-down vote-down' : votingStatusRejected()}" aria-hidden="true"></i>
                </span>
              </div>
              <date-row *ngIf="offerEnvelope.offer.voting?.userGroup" [label]="getVotingLabel()" [ngModel]="offerEnvelope.offer.voting.startDate" name="votingStartDate" [disabled]="true"></date-row>
            </div>
          </div>
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <div class="bon-row">
                <label style="vertical-align: top !important" class="bon-label">{{'offer.voting.approvedBy' | translate}}</label>
                <ul class="vote-users" *ngIf="offerEnvelope.offer.votes">
                  <li *ngFor="let userVote of filteredVotes(true)">
                    <span class="user-name">{{userVote.user.name}} {{userVote.user.familyName}} / {{userVote.userGroup?.name}} {{userVote.date | date}}</span>
                    <span class="user-comment">{{userVote.comment}}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="bon-card-inner">
              <div class="bon-row">
                <label style="vertical-align: top !important" class="bon-label">{{'offer.voting.rejectedBy' | translate}}</label>
                <ul class="vote-users" *ngIf="offerEnvelope.offer.votes">
                  <li *ngFor="let userVote of filteredVotes(false)">
                    <span class="user-name">{{userVote.user.name}} {{userVote.user.familyName}} / {{userVote.userGroup?.name}} {{userVote.date | date}}</span>
                    <span class="user-comment">{{userVote.comment}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </bon-section>
      </form>
    </div>
  </div>
  <div class="additional-buttons">
    <ng-container *ngIf="canVote()">
      <button [btnIcon]="'fa-comment-o'" (click)="voteDialog()" type="button" class="bon-btn-info"
              translate>voting.button
      </button>
    </ng-container>
    <button *ngIf="canCreatePolicy()" (click)="createPolicy()" type="button" class="bon-btn-success" translate>
       offer.button.createPolicy
    </button>
    <item-dropdown *ngIf="showSendToButton()" [items]="userGroups | async" (menuSelect)="sendToGroup($event)" caption="{{'SendTo' | translate}}"
                   [hidden]='!canSaveBlog()'>
    </item-dropdown>
    <lifecycle-dropdown-simple *ngIf="offerEnvelope.offer && offerEnvelope.offer.id && canChangeState()" [service]="offerService"
                               [selectedObject]="offerEnvelope.offer" [saveFunc]="onLifecycle.bind(self)"
                               [inProgress]="inProgress">
    </lifecycle-dropdown-simple>
    <button *ngIf="canBlogOffer()" (click)="createBlogOffer()" type="button" class="bon-btn-success" translate>
      offer.newBlogOffer
    </button>
    <button *ngIf="canCloseVoting()" (click)="closeVoting()" type="button" class="bon-btn-info" translate>
      offer.closeVoting
    </button>
  </div>
  <div class="information-header">
    <div style="float: left;">
      <b class="a-link font-larger" style="float: left; padding: 0 4px;" (click)="routerService.toInquiryDetails(getInquiry())" translate>To Inquiry</b>
      <b class="a-link font-larger" style="float: left; padding: 0 4px;" *ngIf="offerEnvelope.offer.policyContractVersion" (click)="routerService.toPolicyContractPreview(offerEnvelope.offer.policyContractVersion.id)" translate>To Policy</b>
      <b class="font-larger" style="float: left; padding: 0px 4px;" *ngIf="offerEnvelope.offer.number">Number: {{offerEnvelope.offer.number}}</b>
    </div>
  </div>
</details-view>
<form-dialog [parentComponent]="self" size='lg' [showFooter]='false'>
  <form dialogForm="dialogForm" [formGroup]="form" novalidate>
    <div class="bon-row">
      <textarea-row [formControl]="voteCommentControl" label="{{'Comment' | translate}}"
                    rows='3'
                    [control]="voteCommentControl" [showErrors]="true">
      </textarea-row>
    </div>
    <div class="bon-row">
      <button [btnIcon]="'fa-thumbs-o-up'" (click)="vote('up')" type="button" class="bon-btn-info" [disabled]="pending"
              translate>offer.voting.approve
      </button>
      <button [btnIcon]="'fa-thumbs-o-down'" (click)="vote('down')" type="button"
              class="bon-btn-danger" [disabled]="pending" translate>
        offer.voting.reject
      </button>
    </div>
  </form>
</form-dialog>
