import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {DictionaryBaseDto, UserDto} from '../../../bonding_shared/model/dtos';
import {UserService} from '../../../bonding_shared/services/user.service';
import {DictionaryService} from '../../../bonding_shared/services/dictionary.service';
import {TranslateService} from '@ngx-translate/core';
import {GrowlService} from '../../../bonding_shared/services/growl/growl.service';
import {Subject} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../bonding_shared/validators/custom.validators';
import {BackendError} from '../../../bonding_shared/model';
import {UserUtils} from '../../../bonding_shared/utils/user-utils';
import {AppConfigService} from '../../../bonding_shared';

@Component({
  selector: 'user-details',
  templateUrl: 'user-creation.component.html',
})
export class UserCreationComponent implements OnInit {
  user: UserDto = <UserDto>{};
  language: DictionaryBaseDto = <DictionaryBaseDto>{};
  form: UntypedFormGroup = null;
  readonly companySelectorEmitter: Subject<boolean> = new Subject<boolean>();
  serverErrors: BackendError;
  type: string = null;
  businessUnit: number = null;
  title: string = null;
  showErrors = false;
  companyType = '';
  inProgress = false;
  langCodeRegexp: RegExp;

  constructor(
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    private apiService: AppConfigService
  ) {
    this.langCodeRegexp = UserUtils.resolveLangRegexp();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));

    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.required),
      familyName: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          CustomValidators.userFamilyNameFormat,
        ])
      ),
      name: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          CustomValidators.userFirstNameFormat,
        ])
      ),
      language: new UntypedFormControl('', Validators.required),
      company: new UntypedFormControl(''),
    });
  }

  initializeView(params: Params) {
    this.type = params['type'];
    this.businessUnit = params['businessUnit'];
    if (this.type === 'BROKER') {
      this.title = 'Broker';
      this.companyType = 'broker';
    } else if (this.type === 'CLIENT') {
      this.title = 'Client';
      this.companyType = '';
    }
  }

  saveButton() {
    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }
    this.showErrors = false;
    this.inProgress = true;
    this.user = Object.assign(this.user, this.form.value);
    this.userService.createExternalUser(this.user, this.type, this.businessUnit).subscribe(
      (user) => {
        this.user = user;
        this.routerService.toUserDetails(user.id);
      },
      (error) => {
        this.handleServerError(error);
        this.user.password = null;
        this.inProgress = false;
      }
    );
  }

  cancelButton() {
    this.form.reset();
    this.companySelectorEmitter.next(false);
  }

  handleServerError(error: BackendError) {
    this.serverErrors = error;
  }

  onLanguageChange(language: DictionaryBaseDto) {
    this.form.controls['language'].setValue(language);
    this.form.controls['language'].updateValueAndValidity();
  }
}
