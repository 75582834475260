import {Component, OnInit, ViewChild} from '@angular/core';
import {AppConfigService, DictionaryService, GrowlService, UserService} from '../../bonding_shared/services';
import {DictionaryBaseDto, UserRoleQuotaDto} from '../../bonding_shared/model';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {NgForm} from '@angular/forms';
import {
  BusinessLineGroupCompetency,
  DictionaryPropertyType,
  ProfileCategory,
  ProfileQuotaType,
  Rating,
} from '../../bonding_shared/model/dictionary-ids';
import {BusinessPropertiesService} from '../../bonding_shared/services/business-properties.service';

@Component({
  selector: 'user-role-quotas',
  templateUrl: './user-role-quotas.component.pug',
})
export class UserRoleQuotasComponent extends DetailsView implements OnInit {
  profileCategory: DictionaryBaseDto;
  profileCategories: DictionaryBaseDto[];
  private _userRole: DictionaryBaseDto;
  private _dictionary: DictionaryBaseDto;
  private _userRoleQuotas: UserRoleQuotaDto[];
  quotasChanged: boolean;

  hiddenQuotaTypes: Set<number> = new Set();
  hiddenRatings: Set<number> = new Set();

  ProfileCategory = ProfileCategory;

  quotaTypeData = Array<string>();
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  constructor(
    private userService: UserService,
    protected growlService: GrowlService,
    protected businessPropertiesService: BusinessPropertiesService,
    protected appConfig: AppConfigService,
    dictService: DictionaryService
  ) {
    super(growlService);
    this.hiddenRatings.add(Rating.CC0);
    this.hiddenRatings.add(Rating.CC1);
    this.hiddenRatings.add(Rating.CC2);
    this.hiddenRatings.add(Rating.CC3);
    this.hiddenRatings.add(Rating.CC4);
    this.hiddenRatings.add(Rating.CC5);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.objectNotFound = false;
    dictService.getDictionary('ProfileCategory').subscribe((x) => {
      this.userService.getAvailableProfileCategories('USER_ROLE_QUOTA_UPDATE').subscribe((res) => {
        this.profileCategories = x
          .filter((pc) => res.includes(pc.id, 0))
          .filter((p) => p.relatedDictionaries['ProfileQuotaType'].length);
      });
    });
    dictService.getDictionary('ProfileQuotaType').subscribe((res) => {
      res.forEach(
        (dto) => (this.quotaTypeData[dto.id] = dto.properties[DictionaryPropertyType.PROFILE_QUOTA_DATATYPE])
      );
    });
  }

  ngOnInit() {
    this.form = this.ngForm.form;
  }

  onCancel(): void {
    this.clearErrors();
    this.fetchUserRoleQuotas();
  }

  onSave(): void {
    this.clearErrors();
    if (this.formValidates()) {
      this.fillQuotas();
      this.userService.putUserRoleQuotas(this.userRoleQuotas).subscribe({
        next: (quotas) => {
          this.userRoleQuotas = quotas;
          this.showSavedMsg();
        },
        error: (error) => this.handleServerError(error),
      });
    }
  }

  fillQuotas(): void {
    this.userRoleQuotas.forEach((quota) => {
      quota.userRole = this.userRole;
      quota.dictionary = this.dictionary;
    });
  }

  fetchUserRoleQuotas(): void {
    // User role not set
    this.userRoleQuotas = undefined;
    if (!(this.userRole && this.userRole.id)) {
      return;
    } else {
      this.userService
        .getUserRoleQuotasSystemCurrency(this.userRole.id, this.dictionary.id)
        .subscribe((quotas) => (this.userRoleQuotas = quotas));
    }
  }

  get userRole(): DictionaryBaseDto {
    return this._userRole;
  }

  set userRole(userRole: DictionaryBaseDto) {
    if (!this.userRole) {
      this._dictionary = null;
    }
    setTimeout(() => {
      this._userRole = userRole;
      this.fetchUserRoleQuotas();
    });
  }

  get dictionary(): DictionaryBaseDto {
    return this._dictionary;
  }

  set dictionary(value: DictionaryBaseDto) {
    setTimeout(() => {
      this._dictionary = value;
      if (this._dictionary && this._dictionary.id) {
        if (
          this._dictionary.id === BusinessLineGroupCompetency.POLICIES_COMMERCIAL ||
          this._dictionary.id === BusinessLineGroupCompetency.POLICIES_NON_COMMERCIAL
        ) {
          this.hiddenRatings = this.getNumericRatings();
          this.hiddenQuotaTypes.clear();
        }
        if (this._dictionary.id === BusinessLineGroupCompetency.BONDING_COMMERCIAL) {
          this.hiddenRatings = this.getNumericRatings();
          this.hiddenQuotaTypes.add(ProfileQuotaType.MAX_SINGLE_LIMIT);
        }
        if (this._dictionary.id === BusinessLineGroupCompetency.BONDING_NON_COMMERCIAL) {
          this.hiddenRatings = this.getWordRatings();
          this.hiddenQuotaTypes.add(ProfileQuotaType.MAX_SINGLE_LIMIT);
        }
      }
      this.fetchUserRoleQuotas();
    });
  }

  get userRoleQuotas(): UserRoleQuotaDto[] {
    return this._userRoleQuotas;
  }

  set userRoleQuotas(value: UserRoleQuotaDto[]) {
    this._userRoleQuotas = value;
    delete this.quotasChanged;
  }

  isCategory(id: number): boolean {
    return this.profileCategory && this.profileCategory.id === id;
  }

  isRatingCategory(): boolean {
    return this.isCategory(ProfileCategory.CREDENDO_UNDERWRITER);
  }

  private getNumericRatings(): Set<number> {
    const hiddens: Set<number> = new Set();
    hiddens.add(Rating.CC0);
    hiddens.add(Rating.CC1);
    hiddens.add(Rating.CC2);
    hiddens.add(Rating.CC3);
    hiddens.add(Rating.CC4);
    hiddens.add(Rating.CC5);
    return hiddens;
  }

  private getWordRatings(): Set<number> {
    const hiddens: Set<number> = new Set();
    hiddens.add(Rating.CC0);
    hiddens.add(Rating.CC1);
    hiddens.add(Rating.CC2);
    hiddens.add(Rating.CC3);
    hiddens.add(Rating.CC4);
    hiddens.add(Rating.CC5);
    return hiddens;
  }
}
