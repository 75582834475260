import {Component, Input} from '@angular/core';
import {ClientInfoDto, CompanyRatingVersionDto} from '../../../../bonding_shared/model/dtos';
import {AppConfigService} from '../../../../bonding_shared/services';

@Component({
  selector: 'company-segment-info',
  templateUrl: './company-segment-info.component.pug',
})
export class CompanySegmentInfoComponent {
  @Input() rating: CompanyRatingVersionDto = undefined;
  @Input() clientInfo: ClientInfoDto = undefined;

  constructor(public appService: AppConfigService) {}
}
