import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {DictionaryBaseDto, UserDto} from '../../../bonding_shared/model/dtos';
import {UserStatus} from '../../../bonding_shared/model/dictionary-ids';
import {UserGroupBaseDto} from '../../../bonding_shared/model';
import {GrowlService} from '../../../bonding_shared/services/growl/growl.service';
import {UserUtils} from '../../../bonding_shared/utils/user-utils';

@Component({
  selector: 'ad-user-data',
  templateUrl: 'ad-user-data.component.html',
})
export class AdUserDataComponent extends DetailsView implements OnInit {
  readonly UserStatus = UserStatus;
  _user: UserDto = <UserDto>{roles: new Array()};
  userNotEditable = false;
  @Input() header: string;
  totalCount: number;
  errorMessage: string;
  showErrors = false;
  confirmPassword = '';
  _groups: UserGroupBaseDto[];
  groupsForProfileCategory: UserGroupBaseDto[] = new Array();
  groupsForBu: UserGroupBaseDto[] = new Array();
  langCodeRegexp: RegExp;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  @Input() set user(user: UserDto) {
    if (user) {
      this._user = user;
    }
  }

  @Input() set groups(groups: UserGroupBaseDto[]) {
    this._groups = groups;
    if (this.user.businessUnit) {
      this.setGroupsForBu();
      this.removeAssignedGroups();
    }
  }

  get user(): UserDto {
    return this._user;
  }

  constructor(protected growlService: GrowlService) {
    super(growlService);
    this.langCodeRegexp = UserUtils.resolveLangRegexp();
  }

  reset() {
    this.showErrors = false;
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this.selectorNameList = ['Company', 'CreatorCompany'];
  }

  onBUChange(bu: DictionaryBaseDto) {
    this.form.controls['businessUnit'].setValue(bu);
    this.form.controls['businessUnit'].updateValueAndValidity();
    this.setGroupsForBu();
    this.user.groups = [];
  }

  private setGroupsForBu() {
    this.groupsForBu = this._groups.sort((a, b) => (a.name > b.name ? 1 : -1));
    this.groupsForProfileCategory = this.groupsForBu;
  }

  private removeAssignedGroups() {
    this.user.groups.forEach((g) => this.removeFromCollectionById(g.id, this.groupsForBu));
  }

  addGroup(role: UserGroupBaseDto) {
    if (this.userNotEditable) {
      return;
    }
    this.removeFromCollectionById(role.id, this.groupsForProfileCategory);
    this.removeFromCollectionById(role.id, this.groupsForBu);
    this.user.groups.push(role);
  }

  removeGroup(group: UserGroupBaseDto) {
    if (this.userNotEditable) {
      return;
    }
    this.removeFromCollectionById(group.id, this.user.groups);
    this.groupsForProfileCategory.push(group);
    this.groupsForBu.push(group);
  }

  removeFromCollectionById(id: number, list: any[]) {
    for (let i = 0; i < list.length; i++) {
      if (id === list[i].id) {
        list.splice(i, 1);
        break;
      }
    }
  }
}
