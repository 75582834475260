import {Component, Input} from '@angular/core';
import {BusinessObjectType, LimitRequestType} from '../../../bonding_shared/model/dictionary-ids';
import {
  AppConfigService,
  BusinessPropertiesService,
  PolicyInquiryVersionDto,
  RouterService,
} from '../../../bonding_shared';

@Component({
  selector: 'inquiry-new-limit-dropdown',
  templateUrl: 'inquiry-new-limit-dropdown.component.pug',
})
export class InquiryNewLimitDropdownComponent {
  readonly self = this;
  readonly BusinessObjectType = BusinessObjectType;

  @Input() set inquiry(inq: PolicyInquiryVersionDto) {
    this._inquiry = inq;
  }

  get inquiry() {
    return this._inquiry;
  }

  private _inquiry: PolicyInquiryVersionDto;

  constructor(
    private routerService: RouterService,
    public appService: AppConfigService,
    protected businessPropertiesService: BusinessPropertiesService
  ) {}

  onNewLimit() {
    this.routerService.toLimitDetailsWithParams({
      policyInquiryId: this.inquiry.policyInquiry.id,
      typeId: LimitRequestType.STANDARD,
    });
  }

  get showNewLimitButton() {
    return (
      this.inquiry &&
      this.inquiry.id &&
      this.businessPropertiesService.properties &&
      this.businessPropertiesService.properties.policyInquiryProductsForLimit.includes(this.inquiry.productType.id)
    );
  }
}
