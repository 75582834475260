import {Component, OnInit} from '@angular/core';
import {
  AppConfigService,
  BondingContractInquiryVersionSimpleDto,
  CompanySimpleDto,
  LoggedUserService,
  RouterService,
  UserGroupBaseDto,
  UserService,
} from '../../../bonding_shared';
import {SearchView} from '../../../bonding_shared/components/search/search-view/search-view';
import {BondingContractInquiryGuiService} from './services/bonding-contract-inquiry-gui.service';
import {
  BondingElementaryRight,
  Feature,
  getContractTypeCodeRegexp,
  UserGroupType,
} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'bonding-contract-inquiry-search',
  templateUrl: './bonding-contract-inquiry-search.component.pug',
})
export class BondingContractInquirySearchComponent extends SearchView implements OnInit {
  portalMode = false;

  VOTING_GROUP_TYPES = [UserGroupType.BONDING, UserGroupType.POLICY_OFFER_VOTING, UserGroupType.BONDING_OFFER_VOTING];

  public votingGroups: UserGroupBaseDto[];

  readonly BondingElementaryRight = BondingElementaryRight;
  readonly contractTypeCodeRegexp;
  readonly Feature = Feature;

  constructor(
    public guiService: BondingContractInquiryGuiService,
    private loggedUserService: LoggedUserService,
    private appService: AppConfigService,
    public router: RouterService,
    private userService: UserService
  ) {
    super();
    this.portalMode = this.loggedUserService.portal;
    this.contractTypeCodeRegexp = getContractTypeCodeRegexp();
  }

  ngOnInit() {
    this.userService
      .getUserGroups()
      .subscribe(
        (groups) =>
          (this.votingGroups = groups.filter(
            (g) => g.type && g.type.id && this.VOTING_GROUP_TYPES.indexOf(g.type.id) > -1
          ))
      );
  }

  get criteria() {
    return this.guiService.dataProvider.searchCriteria.criteria;
  }

  getClientCompany(iv: BondingContractInquiryVersionSimpleDto): CompanySimpleDto {
    return iv.mainClient;
  }

  onClientClicked(iv: BondingContractInquiryVersionSimpleDto) {
    const c = iv.mainClient || iv.inquiry.client.company;
    if (c) {
      this.router.toCompanyPreview(c.id);
    } else if (!this.portalMode) {
      this.router.toThirdPartyDetails(iv.inquiry.client.id);
    }
  }
}
