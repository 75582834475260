import {Component, OnInit} from '@angular/core';
import {
  AppConfigService,
  BusinessUnitDto,
  BusinessUnitService,
  ComboItem,
  DocumentService,
  FeatureService,
  LoggedUserService,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionSimpleDto,
  RouterService,
  SearchDataProvider,
  UserIdDto,
  UserService,
} from '../../bonding_shared';
import {InquiryGuiService} from './services/inquiry-gui.service';
import {Feature, PolicyElementaryRight} from '../../bonding_shared/model/dictionary-ids';
import {getInitializedCriteria} from './domain/inquiry-common';
import {UserResponsibleService} from '../../bonding_shared/services/user-responsible.service';

@Component({
  selector: 'inquiry-search',
  templateUrl: './inquiry-search.component.pug',
})
export class InquirySearchComponent implements OnInit {
  readonly PolicyElementaryRight = PolicyElementaryRight;

  dataProvider: SearchDataProvider<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>;
  businessUnits: BusinessUnitDto[];
  versionPhases: ComboItem[] = [new ComboItem('LAST', 'Last')];
  salesUsers: UserIdDto[] = null;
  underwriters: UserIdDto[] = null;
  public showShortNameIfExists: boolean; // show short company name instead of registration name when it exists on search view

  readonly hiddenStatuses: Set<number> = new Set();
  readonly Feature = Feature;

  constructor(
    public routerService: RouterService,
    private businessUnitService: BusinessUnitService,
    private loggedUserService: LoggedUserService,
    public guiService: InquiryGuiService,
    public appService: AppConfigService,
    public documentService: DocumentService,
    private userService: UserService,
    private featureService: FeatureService,
    private userResponsibleService: UserResponsibleService
  ) {
    this.dataProvider = guiService.dataProvider;
    this.showShortNameIfExists = featureService.get(Feature.COMPANY_SHOW_SHORT_NAME_IF_EXISTS);
    // this.dataProvider = new SearchDataProvider<PolicyInquiryCriteriaDto, PolicyInquiryDto>(guiService.inquiryService)
  }

  ngOnInit() {
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initializeCriteria();
    }
    this.businessUnitService.getBusinessUnits().subscribe((data) => {
      this.businessUnits = data;
    });
    this.loadSalesUsers();
    this.loadUnderwriters();
  }

  initializeCriteria() {
    this.dataProvider.searchCriteria.criteria = getInitializedCriteria(false, false);
    this.dataProvider.searchCriteria.criteria.withLimitAgregated = false;
    this.dataProvider.textSearch = false;

    this.dataProvider.searchCriteria.criteria.userResponsible =
      this.userResponsibleService.getUserResponsibleSearchCriteria();
  }

  downloadFocus() {
    this.documentService.downloadFocus();
  }

  protected loadSalesUsers() {
    this.userService.getSalesUsers().subscribe((users) => {
      this.salesUsers = users;
    });
  }

  protected loadUnderwriters() {
    this.userService.getPolicySupport().subscribe((users) => {
      this.underwriters = users;
    });
  }

  navigateToDetails(inquiry: PolicyInquiryVersionSimpleDto) {
    this.routerService.toInquiryDetails(inquiry);
  }

  isLinkAvailable(item: PolicyInquiryVersionSimpleDto): boolean {
    return (
      item.fullAccess &&
      this.loggedUserService.hasRight(
        item.policyInquiry.inquiryType === 'POLICY_INQUIRY'
          ? PolicyElementaryRight.POLICY_INQUIRY_VIEW
          : PolicyElementaryRight.POLICY_ANNEX_INQUIRY_VIEW
      )
    );
  }
}
