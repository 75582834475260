import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  AppConfigService,
  ATableComponent,
  CompanySimpleDto,
  DictionaryBaseDto,
  DictionaryDto,
  DictionaryService,
  GrowlService,
  IMultiSelectSettings,
  LayerDto,
  LoggedUserService,
  ReinsurerDto,
  RouterService,
  SearchDataProvider,
  StringUtils,
  TreatyRiskCompanyDto,
  TreatyVersionCriteriaDto,
  TreatyVersionDto,
  TreatyVersionService,
  TreatyVersionSimpleDto,
  TreatyWithoutVersionsDto,
} from '../../bonding_shared';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {
  BusinessObjectType,
  DictionaryPropertyType,
  ElementaryRight,
  ReinsuranceType,
  RepoDocumentElementaryRight,
  TreatyStatus,
} from '../../bonding_shared/model/dictionary-ids';
import {DateUtils} from '../../bonding_shared/utils/date-utils';

@Component({
  selector: 'treaty-details',
  templateUrl: './treaty-details.component.pug',
})
export class TreatyDetailsComponent extends DetailsView implements OnInit {
  readonly ReinsuranceType = ReinsuranceType;
  readonly BusinessObjectType = BusinessObjectType;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  readonly ElementaryRight = ElementaryRight;
  readonly TreatyStatus = TreatyStatus;
  treatyNumberWithVersion: string;
  treatyVersion: TreatyVersionDto;
  defaultReinsurerValue = 100;
  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: false,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
  };
  settlementPeriodicityCodeRegexp: RegExp = /^(?:M|Q|6M|Y)$/;
  versionsDataProvider: SearchDataProvider<TreatyVersionCriteriaDto, TreatyVersionSimpleDto>;
  policyContractTypes: DictionaryDto[];

  _reinsurerList: ATableComponent<ReinsurerDto>;
  _specialRiskList: ATableComponent<TreatyRiskCompanyDto>;
  _layers: ATableComponent<LayerDto>;
  disableglobalLOL = false;
  disableQuota = false;
  rateAdjustmentNumberOfDecimalPlaces = this.getRateAdjustmentNumberOfDecimalPlaces();

  @ViewChild('reinsurerList')
  set reinsurerList(reinsurerList: ATableComponent<ReinsurerDto>) {
    this._reinsurerList = reinsurerList;
  }

  get reinsurerList(): ATableComponent<ReinsurerDto> {
    return this._reinsurerList;
  }

  @ViewChild('specialRiskList')
  set specialRiskList(specialRiskList: ATableComponent<TreatyRiskCompanyDto>) {
    this._specialRiskList = specialRiskList;
  }

  get specialRiskList(): ATableComponent<TreatyRiskCompanyDto> {
    return this._specialRiskList;
  }

  @ViewChild('layers')
  set layers(layers: ATableComponent<LayerDto>) {
    this._layers = layers;
  }

  get layers(): ATableComponent<LayerDto> {
    return this._layers;
  }

  @ViewChild('versions', {static: true}) versionsTable: ATableComponent<TreatyVersionSimpleDto>;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    public treatyService: TreatyVersionService,
    public router: RouterService,
    private dictionaryService: DictionaryService,
    private appService: AppConfigService,
    private loggedUserService: LoggedUserService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.saveButton.hidden = true;
    this.cancelButton.hidden = true;
    this.newVersionButton.hidden = false;
    this.initializeSelectors();
    this.versionsDataProvider = BusinessUtils.createTreatyVersionDataProvider(this.treatyService);
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this.treatyVersion = <TreatyVersionDto>{};
    this.treatyVersion.treaty = <TreatyWithoutVersionsDto>{};
    this.dictionaryService.getDictionary('PolicyContractType').subscribe((dict) => (this.policyContractTypes = dict));
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params, force?: boolean) {
    const id = +params['id'];
    const treatyId = +params['treatyId'];
    if (id === this.treatyVersion.id && !force) {
      return;
    }
    this.serverErrors = undefined;

    if (id > 0 || treatyId) {
      this.loadTreatyVersion(id, treatyId);
    } else {
      this.treatyService.getInitialVersion().subscribe((treatyVersion) => {
        this.treatyVersion = treatyVersion;
        this.handleButtons();
      });
    }
  }

  initializeSelectors() {
    this.selectorNameList = ['Reinsurer', 'SpecialRisks', 'Broker'];
    this.initializeSelectorEmitters(true);
  }

  loadTreatyVersion(id: number, treatyId: number) {
    this.inProgress = true;
    const treatyObs = id
      ? this.treatyService.getById<TreatyVersionDto>(id)
      : this.treatyService.getLastVersion(treatyId);

    treatyObs.subscribe({
      next: (treatyVersion) => {
        this.treatyVersion = treatyVersion;
        this.inProgress = false;
        this.refreshVersionList();
        this.handleButtons();
        this.setTreatyNumberWithVersion(false);
      },
      error: (error) => this.handleServerError(error),
    });
  }

  onSave() {
    this.showErrors = true;
    const treatyToSave = this.treatyVersion;
    if (!this.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFields(this.form);
      return;
    } else {
      this.inProgress = true;
      this.treatyService.save(treatyToSave).subscribe({
        next: (treatyVersion) => {
          this.treatyVersion = treatyVersion;
          this.refreshVersionList();
          this.handleButtons();
          this.serverErrors = undefined;
          this.showErrors = false;
          this.showSavedMsg();
        },
        error: (error) => this.handleServerError(error),
        complete: () => {
          this.inProgress = false;
          this.router.toTreatyVersionDetails(this.treatyVersion.id);
        },
      });
    }
  }

  addSpecialRisk(company: CompanySimpleDto) {
    const specialRisk: TreatyRiskCompanyDto = <TreatyRiskCompanyDto>{};
    specialRisk.company = company;
    this.specialRiskList.onAddItem(specialRisk);
  }

  addReinsurer(company: CompanySimpleDto) {
    const reinsurer: ReinsurerDto = <ReinsurerDto>{};
    reinsurer.company = company;
    reinsurer.leader = false;
    reinsurer.value = this.remainingValue();
    this.reinsurerList.onAddItem(reinsurer);
    // Adding reinsurer with a-table.addItem works better here then with reinsurers.push,
    // because it leaves the item in edit mode and requires user to confirm changes,
    // thus makes user less prone to trying to save treatyVersion without setting value to new reinsurer.
  }

  onCancel() {
    super.onCancel(this.route);
  }

  showSavedMsg() {
    this.growlService.notice('Treaty is saved!');
  }

  onQuotaValueChanged() {
    this.treatyVersion.treaty.retentionValue = 100 - this.treatyVersion.treaty.quotaValue;
    this.setQuotaValues();
  }

  setQuotaValues() {
    let currentQuotaValue = 0;
    for (const item of this.reinsurerList.items) {
      if (item.value) {
        item.quotaValue = (item.value * this.treatyVersion.treaty.quotaValue) / 100;
        const validFromBeforeToday =
          item.validFrom === undefined || DateUtils.compare(DateUtils.today(), item.validFrom) >= 0;
        const validToAfterToday = item.validTo === undefined || DateUtils.compare(DateUtils.today(), item.validTo) <= 0;
        if (validFromBeforeToday && validToAfterToday) {
          currentQuotaValue += item.quotaValue;
        }
      }
    }
    this.treatyVersion.treaty.currentQuotaValue = currentQuotaValue;
    this.treatyVersion.treaty.currentRetentionValue = 100 - this.treatyVersion.treaty.currentQuotaValue;
  }

  remainingValue() {
    let remainingValue = this.defaultReinsurerValue;
    for (const item of this.reinsurerList.items) {
      if (item.value) {
        remainingValue = remainingValue - item.value;
      }
    }
    return remainingValue;
  }

  onCreateNewVersion() {
    this.serverErrors = undefined;
    this.newVersion(this.treatyVersion.treaty.id);
  }

  newVersion(treatyId: number) {
    this.treatyService.newVersion<TreatyVersionDto>(treatyId).subscribe((t) => {
      this.treatyVersion = t;
      this.handleButtons();
      this.setTreatyNumberWithVersion(true);
    });
  }

  newVersionNewTreaty() {
    this.treatyService.newVersionNewTreaty(this.treatyVersion.treaty.id).subscribe((t) => {
      this.treatyVersion = t;
      this.handleButtons();
    });
  }

  onSelectVersion(version: TreatyVersionSimpleDto) {
    this.loadTreatyVersion(version.id, undefined);
  }

  private refreshVersionList() {
    this.versionsDataProvider.searchCriteria.criteria.treaty.id = this.treatyVersion.treaty.id;
    this.versionsTable.search();
  }

  handleButtons() {
    const treatyCreateUpdate = this.loggedUserService.hasRight(ElementaryRight.TREATY_CREATE_UPDATE);
    const treatyActive = this.treatyVersion.status.id === TreatyStatus.ACT;
    this.saveButton.disabled = this.cancelButton.disabled = this.treatyVersion.id && !this.treatyVersion.last;
    this.cancelButton.hidden = this.saveButton.hidden = !treatyCreateUpdate || treatyActive;
  }

  handleProducts() {
    if (!this.treatyVersion.bonding) {
      this.treatyVersion.bondingProducts.length = 0;
    }
    if (!this.treatyVersion.insurance) {
      this.treatyVersion.policyProducts.length = 0;
    }
  }

  lifecycleDropdownButtonVisible(): boolean {
    return (
      this.loggedUserService.hasRight(ElementaryRight.TREATY_CREATE_UPDATE) &&
      this.treatyVersion &&
      this.treatyVersion.id &&
      this.treatyVersion.last &&
      this.loggedUserService.hasRight(ElementaryRight.TREATY_ACTIVATION)
    );
  }

  reinsurerCompanyType(): 'insurer' {
    function allowsNonInsurers(policyProduct: DictionaryBaseDto, products: DictionaryDto[]) {
      const productWithProperties = products && products.find((p) => p.id === policyProduct.id);
      return (
        productWithProperties &&
        productWithProperties.properties &&
        productWithProperties.properties[DictionaryPropertyType.ALLOW_NON_INSURERS_IN_TREATY] === 'true'
      );
    }

    return this.treatyVersion.policyProducts &&
      this.treatyVersion.policyProducts.every((pp) => allowsNonInsurers(pp, this.policyContractTypes)) &&
      (!this.treatyVersion.bondingProducts || this.treatyVersion.bondingProducts.length === 0)
      ? undefined
      : 'insurer';
  }

  addLayer() {
    this.layers.onAddItem(<LayerDto>{layerNumber: this.layers.items.length + 1});
  }

  layerDeletable(item: LayerDto): boolean {
    return this.layers && item.layerNumber === this.layers.items.length;
  }

  reinsuranceTypeChange(t: DictionaryBaseDto) {
    if (t.id === ReinsuranceType.XOL) {
      this.treatyVersion.treaty.quotaValue = 100;
      this.treatyVersion.treaty.retentionValue = 0;
    }
    if (t.id === ReinsuranceType.FAC) {
      this.treatyVersion.insurance = false;
      this.treatyVersion.policyProducts = [];
    }
  }

  newVersionCreationPossible() {
    return (
      this.treatyVersion.id &&
      this.treatyVersion.last &&
      this.loggedUserService.hasRight(ElementaryRight.TREATY_CREATE_UPDATE)
    );
  }

  duplicateButtonDisabled() {
    return (
      (!this.treatyVersion.id || !this.treatyVersion.last) &&
      this.loggedUserService.hasRight(ElementaryRight.TREATY_CREATE_UPDATE)
    );
  }

  getRateAdjustmentNumberOfDecimalPlaces(): number {
    return 2;
  }

  setTreatyNumberWithVersion(newVersion: boolean) {
    let v = this.treatyVersion.versionNumber;
    if (newVersion) {
      v = this.treatyVersion.versionNumber + 1;
    }
    this.treatyNumberWithVersion = this.treatyVersion.treaty.number + '/' + v;
  }
}
