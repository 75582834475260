import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AppConfigService,
  BondingContractInquiryVersionCriteriaDto,
  BondingContractInquiryVersionSimpleDto,
  SearchDataProvider,
  SearchResult,
} from '../../../../../bonding_shared';
import {Subject} from 'rxjs';
import {TextSearchCriteria} from '../../../../../bonding_shared/components/search/model/text-search-criteria';
import {BondingContractInquiryService} from '../../../../../bonding_shared/services/bonding-contract-inquiry.service';

@Component({
  selector: 'bonding-contract-inquiry-version-selector-simple',
  templateUrl: './bonding-contract-inquiry-version-selector-simple.component.pug',
})
export class BondingContractInquiryVersionSelectorSimpleComponent implements OnInit {
  @Input() title: string;
  @Input() labelKey: string;
  /**
   * Open selector event emitter
   * The same emitter instance should be injected into this component and the component that opens this selector
   */
  @Input() open: Subject<boolean>;

  /**
   * when fixedSearch = true, search is done by injected, predefined criteria, user cannot modify them
   */
  @Input() fixedSearch = false;

  /**
   * to be able to switch between advanced/basic search mode
   * TODO: advanced search mode not implemented yet
   */
  _searchModeSwitchable = false;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    return this._searchModeSwitchable;
  }

  /**
   * If data providers gave one row it is selected by default.
   */
  @Input() autoSelectOne = false;

  /**
   *
   */
  @Input() autoSelectId: number = null;

  /**
   * don't use together with textCriteria param
   */
  @Input() set onlyLastVersion(ol: boolean) {
    this.dataProvider.textSearchCriteria.last = ol;
    this.dataProvider.searchCriteria.criteria.last = ol;
  }

  /**
   * don't use together with textCriteria param
   */
  @Input() set onlyActiveVersion(ol: boolean) {
    this.dataProvider.textSearchCriteria.active = ol;
    this.dataProvider.searchCriteria.criteria.presentNow = ol;
  }

  @Input() set criteria(c: BondingContractInquiryVersionCriteriaDto) {
    this.dataProvider.textSearch = false;
    this.dataProvider.searchCriteria.criteria = c;
  }

  @Input() set textSearch(ts: boolean) {
    this.dataProvider.textSearch = ts;
  }

  /**
   * don't use together with onlyLastVersion or onlyActiveVersion param
   */
  @Input() set textCriteria(c: TextSearchCriteria) {
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = c;
  }

  /**
   *  To show only contract properties, not contract version properties
   */
  @Input() onlyContractFields = false;

  @Output() selectItem = new EventEmitter<BondingContractInquiryVersionSimpleDto>();

  @Input() set remoteDataProvider(
    dp: SearchDataProvider<BondingContractInquiryVersionCriteriaDto, BondingContractInquiryVersionSimpleDto>
  ) {
    this.dataProvider = dp;
  }

  pageSize = 10;

  dataProvider: SearchDataProvider<BondingContractInquiryVersionCriteriaDto, BondingContractInquiryVersionSimpleDto>;

  constructor(private service: BondingContractInquiryService, private appService: AppConfigService) {
    this.dataProvider = new SearchDataProvider<
      BondingContractInquiryVersionCriteriaDto,
      BondingContractInquiryVersionSimpleDto
    >(this.service);
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = new TextSearchCriteria();
    this.dataProvider.searchCriteria.criteria = <BondingContractInquiryVersionCriteriaDto>{};
  }

  ngOnInit() {
    if (this.autoSelectId) {
      this.pageSize = null; // if autoselect then no paging because it can break autoselect
    }
  }

  onChange(bondingContractInquiry: BondingContractInquiryVersionSimpleDto) {
    this.selectItem.emit(bondingContractInquiry);
    this.open.next(false);
  }

  onSearchFinished(searchResult: SearchResult<BondingContractInquiryVersionSimpleDto>): void {
    const bondingContractInquiries = searchResult.result;
    if (bondingContractInquiries) {
      if (this.autoSelectId) {
        const autoSelectedContract = bondingContractInquiries.find((cv) => cv.id === this.autoSelectId);
        if (autoSelectedContract) {
          this.onChange(autoSelectedContract);
          return;
        }
      }
      if (this.autoSelectOne && searchResult.size === 1) {
        this.onChange(bondingContractInquiries[0]);
      }
    }
  }
}
