import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {NgForm, UntypedFormBuilder, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {
  AppConfigService,
  ATableComponent,
  BusinessPropertiesService,
  BusinessUnitDto,
  BusinessUnitService,
  CacheService,
  CompanyService,
  CompanySimpleDto,
  ContactPersonCriteriaDto,
  DictionaryBaseDto,
  DictionaryDto,
  DictionaryService,
  DocumentService,
  GrowlService,
  InquiryService,
  LoggedUserService,
  PolicyInquiryCoInsurerCompanyDto,
  PolicyInquiryPolicyDataDto,
  PolicyInquiryProductConfigurationDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionDto,
  PolicyInquiryVersionSimpleDto,
  RelatedObject,
  RouterService,
  SearchDataProvider,
  StringUtils,
  TemplateSimpleDto,
  ThirdPartyBaseDto,
} from '../../bonding_shared';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BusinessObjectType,
  ContactNoteElementaryRight,
  InquiryProductTypes,
  PolicyElementaryRight,
  PolicyInquiryCompanyRole,
  PolicyInquiryStatus,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
  ServiceNoteElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {AbstractInquiryProduct} from './forms/abstract-inquiry.product';
import {AsyncSubject, forkJoin as observableForkJoin, Observable} from 'rxjs';
import {DetailsViewComponent} from '../../bonding_shared/components/details-view/details-view.component';

declare var $: JQueryStatic;

@Component({
  selector: 'inquiry-details',
  templateUrl: './inquiry-details.component.pug',
})
export class InquiryDetailsComponent extends DetailsView implements OnInit {
  versionsDataProvider: SearchDataProvider<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>;
  selectedVersionId: string;

  potentialClientCompanyTypeIds: number[];
  potentialClientCountryId: number;

  inquiry: PolicyInquiryVersionDto;
  templates: TemplateSimpleDto[];

  businessUnits: BusinessUnitDto[];

  contactPersonCriteria: ContactPersonCriteriaDto;
  productConfiguration: PolicyInquiryProductConfigurationDto;

  policy: PolicyInquiryPolicyDataDto;

  inquiryProductTypeSet: boolean;

  readonly: boolean;
  newVersionAvailable = false;

  readonly BusinessObjectType = BusinessObjectType;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  readonly PolicyElementaryRight = PolicyElementaryRight;

  readonly getReportActions = [
    {id: 1, name: 'inquiry.getReportButton.focusExcel'},
    {id: 2, name: 'inquiry.getReportButton.offerComparison'},
  ];
  readonly tasks = [
    {id: 1, name: 'common.showTask'},
    {id: 2, name: 'common.newTask'},
  ];

  @ViewChild('detailsView', {static: true}) detailsViewComponent: DetailsViewComponent;
  @ViewChild('versions', {static: true}) versionsTable: ATableComponent<PolicyInquiryVersionSimpleDto>;
  _detailsProductComponent: AbstractInquiryProduct;

  _ngForm: NgForm;
  @ViewChild('ngForm')
  set ngForm(ngForm: NgForm) {
    this._ngForm = ngForm;
  }

  get ngForm(): NgForm {
    return this._ngForm;
  }

  get detailsProductComponent() {
    return this._detailsProductComponent;
  }

  @ViewChild('detailsProductComponent')
  set detailsProductComponent(detailsProductComponent: AbstractInquiryProduct) {
    this._detailsProductComponent = detailsProductComponent;
  }

  public myOptions: DictionaryDto[];
  public countryGroupsOptions: DictionaryDto[];
  public newCompany: boolean;

  constructor(
    private route: ActivatedRoute,
    public routerService: RouterService,
    private documentService: DocumentService,
    private dictionaryService: DictionaryService,
    private businessUnitService: BusinessUnitService,
    private companyService: CompanyService,
    formBuilder: UntypedFormBuilder,
    public loggedUserService: LoggedUserService,
    public inquiryService: InquiryService,
    protected growlService: GrowlService,
    public businessPropertiesService: BusinessPropertiesService,
    public appService: AppConfigService,
    private cacheService: CacheService
  ) {
    super(growlService);
    this.form = formBuilder.group({
      number: [{value: '', disabled: true}],
      subinsureds: formBuilder.group(
        {
          noInfo: [],
          notExist: [],
        },
        this.noInfoNotExistValidator
      ),
      lostReceivables: formBuilder.group(
        {
          noInfo: [],
          notExist: [],
        },
        this.noInfoNotExistValidator
      ),
    });
    this.hideButtons(false);
    this.deleteButton.hidden = true;
    this.newVersionButton.hidden = false;
    this.newVersionButton.disabled = true;
    this.saveButton.hidden = true;

    this.selectorNameList = [
      RelatedObject.CLIENT,
      RelatedObject.BROKER,
      RelatedObject.AGENT,
      RelatedObject.SALESMAN,
      RelatedObject.CONTACT_PERSON,
      RelatedObject.SUBINSUREDS,
      RelatedObject.FL_INSURERS,
      RelatedObject.CURRENT_INSURER,
      RelatedObject.POLICY,
    ];
    this.initializeSelectorEmitters(true);
    this.versionsDataProvider = BusinessUtils.createPolicyInquiryVersionDataProvider(this.inquiryService);
  }

  createRightFunc(object: PolicyInquiryVersionDto) {
    return object.policyInquiry.inquiryType === 'POLICY_INQUIRY'
      ? PolicyElementaryRight.POLICY_INQUIRY_CREATE
      : PolicyElementaryRight.POLICY_ANNEX_INQUIRY_CREATE;
  }

  updateRightFunc(object: PolicyInquiryVersionDto) {
    return object.policyInquiry.inquiryType === 'POLICY_INQUIRY'
      ? PolicyElementaryRight.POLICY_INQUIRY_UPDATE
      : PolicyElementaryRight.POLICY_ANNEX_INQUIRY_UPDATE;
  }

  updateValidators() {
    if (this.inquiryTypeSwitch() === 'CREDENDO' || this.inquiryTypeSwitch() === 'CREDENDO_SINGLE_RISK') {
      if (this.subinsuredEmpty()) {
        this.setAndUpdateValidator('subinsureds', this.noInfoNotExistValidator);
      } else {
        this.setAndUpdateValidator('subinsureds', null);
      }
    }
    if (this.inquiryTypeSwitch() === 'CREDENDO') {
      if (this.lostReceivablesEmpty()) {
        this.setAndUpdateValidator('lostReceivables', this.noInfoNotExistValidator);
      } else {
        this.setAndUpdateValidator('lostReceivables', null);
      }
    }
    if (this.inquiryTypeSwitch() === 'CREDENDO') {
      const turnoverInsurable = this.form.get('turnoverInsurable');
      if (turnoverInsurable) {
        turnoverInsurable.updateValueAndValidity();
      }
    }
  }

  noInfoNotExistValidator(group: UntypedFormGroup): ValidationErrors {
    const noInfo = group.get('noInfo');
    const notExist = group.get('notExist');
    return noInfo.value === true || notExist.value === true ? null : {required: true};
  }

  ngOnInit() {
    console.log('START ngOnInit()');
    this.saveButton.hidden = true;
    this.serverErrors = undefined;
    this.contactPersonCriteria = <ContactPersonCriteriaDto>{};
    this.route.params.subscribe((params) => this.initializeView(params));
    this.route.queryParams.subscribe((params) => {
      if ('true' === params['devMode']) {
        this.newVersionAvailable = true;

        this.newVersionButton.hidden = false;
        this.newVersionButton.disabled = false;
      }
    });
    this.loadBusinessUnits();
    this.dictionaryService.getDictionary('Country').subscribe((data) => {
      this.myOptions = data;
    });
    this.inquiryService.getProductConfiguration().subscribe((c) => (this.productConfiguration = c));

    this.dictionaryService.getDictionary('EconomicCountryGroup').subscribe((data) => {
      for (const group of data) {
      }
      this.countryGroupsOptions = data;
    });
  }

  initializeView(params: Params, force?: boolean) {
    console.log('initializeView, params', params);
    const id = +params['id'];
    const clientId = +params['clientId'];
    const typeId = +params['typeId'];
    const buId = +params['buId'];

    this.newCompany = params['readFromCache'] && params['readFromCache'].toLowerCase() === 'true';
    if (this.inquiry && id === this.inquiry.id && !force) {
      return;
    }
    this.initializeInquiry(id, clientId, this.newCompany, typeId, buId);
  }

  loadBusinessUnits() {
    this.businessUnitService.getBusinessUnits().subscribe((businessUnits) => {
      this.businessUnits = businessUnits;
    });
  }

  initializeInquiry(
    inquiryVersionId: number,
    clientId?: number,
    readFromCache?: boolean,
    typeId?: number,
    buId?: number
  ) {
    console.log('initializeInquiry, params', inquiryVersionId, clientId, readFromCache);
    if (!inquiryVersionId) {
      console.log('initialize version');
      if (!readFromCache) {
        this.inquiryService.initialVersion(clientId).subscribe((inqV) => {
          this.setInquiry(inqV);
          this.selectClient(this.inquiry.client);
          if (typeId) {
            this.setType(typeId, inqV, buId);
          }
        });
      } else {
        this.setInquiry(this.cacheService.inquiry);
        if (this.cacheService.inquiry.client) {
          this.selectClient(this.cacheService.inquiry.client);
        }
        if (
          this.cacheService.inquiry &&
          this.cacheService.inquiry.productType &&
          this.cacheService.inquiry.productType.id
        ) {
          const buIdFromCache = this.cacheService.inquiry.businessUnit && this.cacheService.inquiry.businessUnit.id;
          this.setType(this.cacheService.inquiry.productType.id, this.cacheService.inquiry, buIdFromCache);
          this.saveButtonHiddenSwitch();
        }
      }
    } else {
      this.loadInquiry(inquiryVersionId, true);
    }
  }

  private setType(typeId: number, inqV: PolicyInquiryVersionDto, buId?: number) {
    observableForkJoin([
      this.businessUnitService.getBusinessUnit(buId),
      this.dictionaryService.getDictionaryEntry('InquiryProductType', typeId),
    ]).subscribe((res) => {
      const [bu, productType] = res;
      inqV.businessUnit = bu;
      inqV.productType = productType;
      this.onSetInquiryProductType(productType);
    });
  }

  onSave(): Observable<boolean> {
    if (this.inProgress) {
      console.warn('Parallel save clicks');
      return;
    }

    const saveResult = new AsyncSubject<boolean>();
    console.log('onSave, inquiry:', this.inquiry);
    this.showErrors = true;
    this.updateValidators();
    if (!this.form.valid || !this.ngForm.form.valid) {
      let erroMsg = null;
      if ($('.invalid').length === 0) {
        erroMsg = StringUtils.logFormInvalidFieldsRecursive(!this.form.valid ? this.form : this.ngForm.form);
      }
      this.showFormError(erroMsg);
      saveResult.error(null);
    } else {
      this.inProgress = true;
      this.detailsProductComponent.beforeSave(this.inquiry);
      this.inquiryService.save(this.inquiry).subscribe({
        next: (inquiry) => {
          this.setInquiry(inquiry);
          this.serverErrors = undefined;
          this.showErrors = false;
          if (inquiry.warnings && inquiry.warnings.length) {
            this.growlService.warning(inquiry.warnings.map((e) => e.message).join('\n'));
          }
          this.showSavedMsg();
          this.refreshVersionList();
          saveResult.next(true);
          saveResult.complete();
        },
        error: (error) => {
          this.handleServerError(error);
          saveResult.error(null);
        },
        complete: () => {
          this.inProgress = false;
          this.routerService.toPolicyInquiryDetails(this.inquiry.id);
        },
      });
      this.showErrors = false;
    }

    return saveResult.asObservable();
  }

  loadInquiry(versionId: number, refreshVersions: boolean) {
    this.inProgress = true;
    this.inquiryService.getById<PolicyInquiryVersionDto>(versionId).subscribe({
      next: (inquiry) => {
        this.setInquiry(inquiry);
        this.selectedVersionId = '' + inquiry.id;
        if (inquiry.client && inquiry.client.company && inquiry.client.company.id) {
          this.contactPersonCriteria.companyId = this.inquiry.client.company.id;
        }
        if (inquiry.productType) {
          this.setProductTypeSetOn();
        }
        if (refreshVersions) {
          this.refreshVersionList();
        }
        this.inProgress = false;
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
    this.loadPolicyFromInquiry(versionId);
  }

  private loadPolicyFromInquiry(inquiryVersionId?: number) {
    // if inquiryVersionId, only clean policy.
    this.policy = null;
    if (inquiryVersionId) {
      this.inquiryService.findPolicyFromInquiry(inquiryVersionId).subscribe((res) => (this.policy = res));
    }
  }

  showSavedMsg() {
    this.growlService.notice('inquiry.saved');
  }

  showFormError(extractInfo?: string) {
    this.growlService.error('The form has errors!');
    if (extractInfo) {
      this.growlService.error(extractInfo);
    }
    this.inProgress = false;
  }

  onCancel(): void {
    this.potentialClientCompanyTypeIds = undefined;
    this.inquiryProductTypeSet = false;
    super.onCancel(this.route);
  }

  onSelectClientCompanySimple(company: CompanySimpleDto) {
    this.selectClient(<ThirdPartyBaseDto>{company: company});
  }

  private selectClient(client: ThirdPartyBaseDto) {
    this.inquiry.client = client;
    this.inquiry.contactPerson = undefined;
    if (client && client.company && client.company.id) {
      this.contactPersonCriteria.companyId = client.company.id;
      this.companyService.findSalesUser(client.company).subscribe((u) => {
        if (u) {
          this.inquiry.salesPerson = u;
        }
      });
      this.inquiry.naceCode = client.company.naceCode;
    } else {
      this.contactPersonCriteria.companyId = null;
      this.inquiry.salesPerson = null;
    }
    this.saveButtonHiddenSwitch();
  }

  onSetInquiryProductType(policyInquiryProductType: DictionaryBaseDto) {
    if (!this.inquiryProductTypeSet) {
      this.setProductTypeSetOn();
    }
    if (this.productConfiguration?.coInsurerProductIds.includes(policyInquiryProductType.id)) {
      this.setBUAsInitialCoInsurer();
    }
  }

  onCreateNewVersion() {
    this.serverErrors = undefined;
    this.inquiryService.newVersion<PolicyInquiryVersionDto>(this.inquiry.policyInquiry.id).subscribe(
      (bv) => {
        this.setInquiry(bv);
        this.loadPolicyFromInquiry();
      },
      (error) => {
        this.handleServerError(error);
      }
    );
  }

  private refreshVersionList() {
    this.versionsDataProvider.searchCriteria.criteria.policyInquiry.id = this.inquiry.policyInquiry.id;
    this.versionsTable.search();
  }

  onSelectVersion(v: PolicyInquiryVersionSimpleDto) {
    this.routerService.toPolicyInquiryDetails(v.id);
  }

  subinsuredEmpty(): boolean {
    return this.inquiryService.subinsuredEmpty(this.inquiry);
  }

  lostReceivablesEmpty(): boolean {
    return !this.inquiry.lostReceivables || this.inquiry.lostReceivables.length < 1;
  }

  private setInquiry(inqV: PolicyInquiryVersionDto) {
    this.inquiry = inqV;
    this.cacheService.inquiry = inqV;
    this.newVersionButton.disabled = !inqV || !inqV.id;
    this.readonly =
      this.inquiry &&
      this.inquiry.status &&
      (this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_ACCEPTED ||
        this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_CLOSED);
    this.saveButton.disabled = this.readonly;
    this.saveButtonHiddenSwitch();
    this.handleNewVersionAvailable();
  }

  potentialClientEditable(): boolean {
    if (this.inquiry && this.inquiry.status) {
      if (
        this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_ACCEPTED ||
        this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_REJECTED ||
        this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_USER_ERROR
      ) {
        return false;
      }
    }
    return true;
  }

  inquiryTypeSwitch() {
    const typeId = this.inquiry && this.inquiry.productType && this.inquiry.productType.id;
    if (!typeId) {
      return null;
    }
    if (InquiryProductTypes.CREDENDO_MULTI_RISK.includes(typeId)) {
      return 'CREDENDO';
    }
    if (InquiryProductTypes.CREDENDO_SINGLE_RISK.includes(typeId)) {
      return 'CREDENDO_SINGLE_RISK';
    }
    return null;
  }

  isDisplayedOrderReport() {
    return this.inquiryTypeSwitch() === 'CREDENDO' || this.inquiryTypeSwitch() === 'CREDENDO_SINGLE_RISK';
  }

  private setProductTypeSetOn() {
    this.inquiryProductTypeSet = true;
    this.saveButtonHiddenSwitch();
  }

  private saveButtonHiddenSwitch() {
    const inq = this.inquiry;
    const inqReady = inq && inq.productType && inq.businessUnit && inq.client;

    const noRights =
      !this.loggedUserService.hasRight(PolicyElementaryRight.POLICY_INQUIRY_CREATE) &&
      !this.loggedUserService.hasRight(PolicyElementaryRight.POLICY_INQUIRY_UPDATE);

    this.saveButton.hidden = noRights || !inqReady;
  }

  private handleNewVersionAvailable() {
    this.newVersionAvailable =
      this.inquiry && this.inquiry.status && !PolicyInquiryStatus.CLOSED_STATUSES.includes(this.inquiry.status.id);
  }

  getClient() {
    return this.tpCompany().company;
  }

  isClientRaw() {
    return this.tpCompany().raw;
  }

  private tpCompany() {
    return BusinessUtils.getThirdPartyCompany(this.inquiry && this.inquiry.client);
  }

  getReport(id: number) {
    switch (id) {
      case 1:
        this.documentService.downloadInquiryReport(this.inquiry.id);
        break;
      case 2:
        this.documentService.downloadOfferComparisonReport(this.inquiry.id, (errorMsg) =>
          this.downloadErrorCallback(errorMsg)
        );
        break;
      default:
        console.log('Action not defined');
    }
  }

  showOrCreateTask(id: 1 | 2) {
    switch (id) {
      case 1:
        this.routerService.toTaskSearchWithBo(BusinessObjectType.POLICY_INQUIRY, this.inquiry.policyInquiry.id);
        break;
      case 2:
        this.routerService.toNewTaskDetailsWithBO(BusinessObjectType.POLICY_INQUIRY, this.inquiry.policyInquiry.id);
        break;
      default:
        console.log('Action not defined');
    }
  }

  get documentButtonBusinessObjectTypeId(): number {
    return BusinessObjectType.POLICY_INQUIRY_VERSION;
  }

  get documentButtonBusinessObjectId(): number {
    return this.inquiry?.id;
  }

  get showGetReportButton() {
    return this.inquiry?.id && this.isDisplayedOrderReport();
  }

  toPolicyInquiryServiceContacts() {
    this.routerService.toServiceContacts({
      boTypeId: BusinessObjectType.POLICY_INQUIRY,
      boId: this.inquiry.policyInquiry.id,
    });
  }

  setBUAsInitialCoInsurer() {
    if (!this.inquiry.coInsurers || this.inquiry.coInsurers.length < 1) {
      this.inquiry.coInsurers = [];
      const bi = <PolicyInquiryCoInsurerCompanyDto>{
        leader: true,
        role: <DictionaryBaseDto>{id: PolicyInquiryCompanyRole.CO_INSURER},
        company: this.inquiry.businessUnit.company,
      };
      this.inquiry.coInsurers.push(bi);
    }
  }
}
