import {Component, OnInit} from '@angular/core';
import {TreatyGuiService} from './services/treaty-gui.service';
import {
  ComboItem,
  DictionaryBaseDto,
  DictionaryBaseService,
  LocalDateRange,
  RouterService,
  SearchDataProvider,
  TreatyBaseDto,
  TreatyVersionCriteriaDto,
  TreatyVersionSimpleDto,
} from '../../bonding_shared';
import {ElementaryRight} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'treaty-search',
  templateUrl: './treaty-search.component.pug',
})
export class TreatySearchComponent implements OnInit {
  readonly ElementaryRight = ElementaryRight;

  versionPhases: ComboItem[] = [new ComboItem('ACTIVE', 'Current'), new ComboItem('LAST', 'Last')];

  dataProvider: SearchDataProvider<TreatyVersionCriteriaDto, TreatyVersionSimpleDto>;
  constructor(
    public guiService: TreatyGuiService,
    public router: RouterService,
    private dictionaryBaseService: DictionaryBaseService
  ) {
    this.dataProvider = guiService.dataProvider;
  }

  contractTypes: DictionaryBaseDto[];
  policyTypes: DictionaryBaseDto[];

  ngOnInit() {
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initializeCriteria();
    }
    this.dictionaryBaseService.getDictionaryBase('ContractType').subscribe((r) => {
      this.contractTypes = r;
    });
    this.dictionaryBaseService.getDictionaryBase('PolicyContractType').subscribe((r) => {
      this.policyTypes = r;
    });
  }

  initializeCriteria() {
    this.dataProvider.searchCriteria.criteria = <TreatyVersionCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.validFrom = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.validTo = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.treaty = <TreatyBaseDto>{};
    this.dataProvider.textSearch = false;
  }
}
