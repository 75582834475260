<details-view [parentComponent]="self" [fixedBlockHidden]="false" [versionListHidden]="true" modelObjectName="invoice"
              [objectName]="'fiNote.details.objectName' | cattranslate: category" [title]="'fiNote.details.title' | cattranslate: category">
  <div class="additional-buttons">
    <lifecycle-dropdown-simple [service]="fiNoteService" [selectedObject]="fiNote" [disabled] = "createUpdateDisabled"
                               [saveFunc]="onSave.bind(self)" [inProgress]='inProgress'>
    </lifecycle-dropdown-simple>
    <item-dropdown *ngIf="fiNote && fiNote.id > 0" [items]="reportDefinitions" (menuSelect)="onReportDefinitionSelected($event, fiNote.id)" caption="fiNote.details.reports"
                   [dropup]="false" [disabled]="inProgress">
    </item-dropdown>
  </div>
  <div class="details-content" *ngIf="fiNote">
    <form #templateForm="ngForm">
      <div class="bon-card-inner-group">
        <div class="bon-card-inner" style="width: 40%;">
          <company-section [label]="'fiNote.details.client' | cattranslate: category" [(ngModel)]="fiNote.client" #model="ngModel"
                           [clearable]="false"
                           [openSelectorEmitter]="openSelectorEmitters['Client']" [showLink]="true"
                           [readOnly]="false" [control]="model" [showErrors]="showErrors" name="client"
                           [required]="true">
          </company-section>
        </div>
        <div class="bon-card-inner" style="width: 60%;">

        </div>
      </div>
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <company-selector-simple (selectItem)="onCompanySelect($event)" [title]="'fiNote.details.clientSearch' | cattranslate: category"
                                   [open]="openSelectorEmitters['Client']" [showDuplicates]="false" [searchModeSwitchable]="true">
          </company-selector-simple>
        </div>
      </div>
      <bon-section *ngIf="fiNote.client"
                   [title]= "'fiNote.details.notInvoicedItems' | cattranslate: category" [expandable]="!fiNote.id" >
        <div class="bon-card-group">
          <invoice-item-list [relatedToId]="fiNote.client.id"
                             [categoryId]="itemCategoryId"
                             [parentCategoryId]="categoryId"
                             [invoiceCriteria]="invoiceCriteria"
                             [fiNoteCriteria] = "fiNoteCriteria"
                             [showSourceInvoice]="true"
                             [invoiceItemsToBeRemovedFromResults]="fiNote.invoiceItems"
                             (invoiceItemEmitter)="onAddInvoiceItem($event)"
                             (invoiceItemsEmitter)="invoiceAll($event)"></invoice-item-list>
        </div>
      </bon-section>


      <bon-section labelKey="fiNote.details.details">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <input-row labelKey="fiNote.details.number" [(ngModel)]="fiNote.number" [showErrors]="showErrors" name="number" [required]="true"></input-row>
            <form-row labelKey="fiNote.details.businessUnit">
              <business-unit-selector class="bon-input-size" [(ngModel)]="fiNote.businessUnit"
                                      name="businessUnit" (changeItem)="onBusinessUnitChange($event)">
              </business-unit-selector>
            </form-row>
          </div>
          <div class="bon-card-inner">
            <num-row labelKey="fiNote.details.netAmount"  [(ngModel)]="fiNote.netAmount" [showErrors]="showErrors" name="netAmount" [required]="true" [allowNegative] = "true">
            </num-row>
            <dict-row labelKey="fiNote.details.currency" [(ngModel)]="fiNote.currency" [showErrors]="showErrors" [required]="true"
                      dictionary="Currency" name="currency" itemLabel="code" [disabled]="true">
            </dict-row>
          </div>
          <div class="bon-card-inner">
            <date-row [labelKey]="getLabelKeyForCreateInvoice()" [(ngModel)]="fiNote.creationDate" name="creationDate" [disabled]="true">
            </date-row>
            <form-row labelKey="fiNote.details.createdBy" *ngIf="fiNote.createdBy">
              {{fiNote.createdBy.name}} {{fiNote.createdBy.familyName}}
            </form-row>
            <date-row labelKey="fiNote.details.issueDate" [(ngModel)]="fiNote.issueDate" [showErrors]="showErrors" [required]="true" name="issueDate">
            </date-row>
            <date-row labelKey="fiNote.details.dateFrom" [(ngModel)]="fiNote.dateFrom" name="dateFrom"></date-row>
            <date-row labelKey="fiNote.details.dateTo" [(ngModel)]="fiNote.dateTo" name="dateTo" ></date-row>
          </div>
        </div>
      </bon-section>

      <bon-section labelKey="fiNote.details.items">
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <a-table [items]="fiNote.invoiceItems" [editable]="false"
                     [addButton]="false" [deleteButton]="true"
                     [formModel]="form" groupName="invoiceItemsTable" [showAllErrors]="showErrors"
                     [emitDeleteButton]="true" (deleteButtonClick)="onDeleteInvoiceItem($event)"
                     [footer]="true">
              <column labelKey="fiNote.details.item.title" property="title" [link]="true"
                      (linkClick)="router.toInvoiceItemDetails($event.category.id, $event.id)" cellWidth=""></column>
              <column labelKey="fiNote.details.item.client" property="client.registrationName" [link]="true"
                      (linkClick)="router.toCompanyPreview($event.client.id)" cellWidth=""></column>
              <column labelKey="fiNote.details.item.invoice" property="invoicePosition.invoice.number" cellWidth=""></column>
              <column labelKey="fiNote.details.item.businessObjectTypeAndId" (linkClick)="goToBusinessObject($event)" [link]="true">
                <ng-template let-item="item">
                  {{item.businessObject?.relatedTo?.name}} / {{item.businessObject?.description}}
                </ng-template>
              </column>
              <column labelKey="fiNote.details.item.subtype" property="subtype" dictionary="InvoiceItemSubtype"></column>
              <column labelKey="fiNote.details.item.netAmount" property="netAmount" type="number" [sumInFooter]="true"></column>
              <column labelKey="fiNote.details.item.currency" property="currency" dictionary="Currency" dictLabel="code"></column>
            </a-table>
          </div>
        </div>
      </bon-section>

    </form>
  </div>

  <confirm-dialog #removeConfirm></confirm-dialog>
</details-view>
