import {Component, OnInit} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {UserCriteriaDto, UserSimpleDto} from '../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {UserGuiService} from './services/user-gui.service';
import {AppConfigService, DictionaryService, LoggedUserService, UserService} from '../../bonding_shared/services';
import {UserGroupBaseDto} from '../../bonding_shared/model';
import {UserType} from '../../bonding_shared/model/dictionary-ids';
import {ComboItem} from '../../bonding_shared/components/combos';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params} from '@angular/router';
import {BusinessUnit} from '../../bonding_shared/model/business-unit';

@Component({
  selector: 'user-search',
  templateUrl: 'user-search.component.html',
})
export class UserSearchComponent implements OnInit {
  readonly UserType = UserType;

  dataProvider: SearchDataProvider<UserCriteriaDto, UserSimpleDto>;
  userGroups: UserGroupBaseDto[];

  employeeSearchView = false;
  labelKeyCustomTitle = 'searchView.userSearch';

  businessUnitClientsConfiguration: Array<{businessUnit: string; businessUnitCode: number}> = [
    {businessUnit: 'AT', businessUnitCode: BusinessUnit.AT},
    {businessUnit: 'BE', businessUnitCode: BusinessUnit.BE},
    {businessUnit: 'CH', businessUnitCode: BusinessUnit.CH},
    {businessUnit: 'DE', businessUnitCode: BusinessUnit.DE},
    {businessUnit: 'ES', businessUnitCode: BusinessUnit.ES},
    {businessUnit: 'FR', businessUnitCode: BusinessUnit.FR},
    {businessUnit: 'IE', businessUnitCode: BusinessUnit.IE},
    {businessUnit: 'IT', businessUnitCode: BusinessUnit.IT},
    {businessUnit: 'NL', businessUnitCode: BusinessUnit.NL},
    {businessUnit: 'PL', businessUnitCode: BusinessUnit.PL},
  ];

  businessUnitBrokersConfiguration: Array<{businessUnit: string; businessUnitCode: number}> = [
    {businessUnit: 'BE', businessUnitCode: BusinessUnit.BE},
    {businessUnit: 'DE', businessUnitCode: BusinessUnit.DE},
    {businessUnit: 'IT', businessUnitCode: BusinessUnit.IT},
  ];

  statusFilters: ComboItem[] = [];
  hiddenUserRoles: Set<number> = new Set();

  constructor(
    public router: RouterService,
    public guiService: UserGuiService,
    private userService: UserService,
    public loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    public dictService: DictionaryService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    this.statusFilters.push(
      new ComboItem('NEW', this.translateService.instant('user.search.criteria.statusFilter.new'))
    );
    this.statusFilters.push(
      new ComboItem('ACTIVE', this.translateService.instant('user.search.criteria.statusFilter.active'))
    );
    this.statusFilters.push(
      new ComboItem('INACTIVE', this.translateService.instant('user.search.criteria.statusFilter.inactive'))
    );
    this.statusFilters.push(
      new ComboItem('ALL', this.translateService.instant('user.search.criteria.statusFilter.all'))
    );
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.dataProvider = this.guiService.initializeDataProvider();
    this.employeeSearchView = params['employee'] === 'true';
    // checkIsEmployee = true  -> search only employee
    // checkIsEmployee = null  -> search not employee
    // checkIsEmployee = undefined  -> search all
    this.dataProvider.searchCriteria.criteria.checkIsEmployee = this.employeeSearchView === true ? true : undefined;
    if (this.employeeSearchView) {
      this.labelKeyCustomTitle = 'searchView.employeeSearch';
    }
    this.userService.getUserGroups().subscribe((groups) => (this.userGroups = groups));
  }

  newBrokerUser(businessUnit: number) {
    this.router.toUserCreation('BROKER', businessUnit);
  }

  newClientUser(businessUnit: number) {
    this.router.toUserCreation('CLIENT', businessUnit);
  }
}
