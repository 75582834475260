import {BondingComponent} from './bonding.component';
import {HomeRoutes} from './home/home.routes';
import {CompanyRoutes} from './company/company.routes';
import {BondRoutes} from './bond/bond.routes';
import {AuthenticationGuard} from './authentication-guard';
import {TaskRoutes} from './task/task.routes';
import {ReportRoutes} from './report/report.routes';
import {DocumentRoutes} from './document/document.routes';
import {ContractRoutes} from './bonding-contract/contract/contract.routes';
import {BrokerContractRoutes} from './broker-contract/broker-contract.routes';
import {BusinessReportRoutes} from './business-report/business-report.routes';
import {VersionRoutes} from './version-info/version.routes';
import {PolicyContractRoutes} from './policy/policy.routes';
import {LimitRoutes} from './limit/limit.routes';
import {InvoiceRoutes} from './invoice/invoice.routes';
import {InvoiceItemRoutes} from './invoice-item/invoice-item.routes';
import {ClaimRoutes} from './claim/claim.routes';
import {TreatyRoutes} from './treaty/treaty.routes';
import {CountryRatingRoutes} from './country-rating/country-rating.routes';
import {UserRoutes} from './user/user.routes';
import {InquiryRoutes} from './inquiry/inquiry.routes';
import {SalesLeadRoutes} from './sales-lead/sales-lead-routes';
import {ImportRoutes} from './import/import.routes';
import {RepositoryDocumentsRoutes} from './repository-documents/repository-documents.routes';
import {GroupRoutes} from './group/group.routes';
import {PolicyCessionRoutes} from './policy-cession/policy-cession.routes';
import {AdministrationRoutes} from './administration/administration.routes';
import {ServiceContactsRoutes} from './service-contacts';
import {ContactNoteRoutes} from './contactNote/components/shared/contact-note-shared.routes';
import {ServiceNotesRoutes} from './service-notes';
import {SalesRepRoutes} from './sales-rep/sales-rep.routes';
import {ThirdPartyRoutes} from './thirdparty/thirdparty.routes';
import {InvoicePaymentRoutes} from './invoice-payment/invoice-payment.routes';
import {EventRoutes} from './event/event.routes';
import {PolicyLimitListRoutes} from './policy-limit-list/policy-limit-list.routes';
import {ClientCompanyRoutes} from './client-company/client-company.routes';
import {BatchRoutes} from './batch/batch.routes';
import {ReportExecutionRoutes} from './report-execution/report-execution.routes';
import {FINoteRoutes} from './fi-note/fi-note.routes';
import {BondingContractInquiryRoutes} from './bonding-contract/inquiry/bonding-contract-inquiry.routes';
import {BondingContractOfferRoutes} from './bonding-contract/offer/bonding-contract-offer.routes';
import {BiReportRoutes} from './bi-report/bi-report.routes';
import {HRRoutes} from './hr';
import {SharedRoutes} from '../bonding_shared/components/shared-components.routes';

export const BondingRoutes = [
  {
    path: 'bon',
    component: BondingComponent,
    canActivate: [AuthenticationGuard],
    children: [
      ...HomeRoutes,
      ...AdministrationRoutes,
      ...ContractRoutes,
      ...PolicyContractRoutes,
      ...PolicyCessionRoutes,
      ...CompanyRoutes,
      ...GroupRoutes,
      ...BondRoutes,
      ...LimitRoutes,
      ...ReportRoutes,
      ...TaskRoutes,
      ...DocumentRoutes,
      ...BiReportRoutes,
      ...BrokerContractRoutes,
      ...BondingContractInquiryRoutes,
      ...BondingContractOfferRoutes,
      ...BusinessReportRoutes,
      ...ReportExecutionRoutes,
      ...ImportRoutes,
      ...VersionRoutes,
      ...InvoiceRoutes,
      ...InvoiceItemRoutes,
      ...InvoicePaymentRoutes,
      ...FINoteRoutes,
      ...ClaimRoutes,
      ...UserRoutes,
      ...TreatyRoutes,
      ...CountryRatingRoutes,
      ...InquiryRoutes,
      ...SalesLeadRoutes,
      ...RepositoryDocumentsRoutes,
      ...ServiceContactsRoutes,
      ...SalesRepRoutes,
      ...ContactNoteRoutes,
      ...ServiceNotesRoutes,
      ...ThirdPartyRoutes,
      ...ServiceNotesRoutes,
      ...EventRoutes,
      ...PolicyLimitListRoutes,
      ...ClientCompanyRoutes,
      ...BatchRoutes,
      ...HRRoutes,
      ...SharedRoutes,
    ],
  },
];
