import {Component, OnInit} from '@angular/core';
import {BatchGuiService} from './service/batch-gui.service';
import {AppConfigService, LoggedUserService, RouterService, SearchDataProvider} from '../../bonding_shared/services';
import {BatchExecutionDto, BatchSearchCriteriaDto} from '../../bonding_shared/model';
import {ActivatedRoute} from '@angular/router';
import {ComboItem} from '../../bonding_shared/components/combos';
import {StringUtils} from '../../bonding_shared/utils';
import {BatchService} from '../../bonding_shared/services/batch.service';
import {ElementaryRight} from '../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'batch-executions',
  templateUrl: './batch-executions.component.pug',
  providers: [],
})
export class BatchExecutionsComponent implements OnInit {
  dev: boolean;
  readonly BATCH_RUN = ElementaryRight.BATCH_RUN;
  dataProvider: SearchDataProvider<BatchSearchCriteriaDto, BatchExecutionDto>;
  jobNames: ComboItem[] = [];
  statuses: ComboItem[] = [];

  StringUtils = StringUtils;

  ngOnInit(): void {
    this.initializeCriteria();
  }

  constructor(
    batchGuiService: BatchGuiService,
    protected _router: RouterService,
    batchService: BatchService,
    public loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    translateService: TranslateService
  ) {
    this.dataProvider = batchGuiService.searchDataProvider;
    this.dataProvider.textSearch = false;
    this.dev = appService.envCode === 'DEV';
    batchService
      .getJobNames()
      .subscribe((res) => res.forEach((pair) => this.jobNames.push(new ComboItem(pair.key, pair.value))));
    this.statuses.push(
      {value: 'COMPLETED', label: translateService.instant('COMPLETED')},
      {value: 'STARTING', label: translateService.instant('STARTING')},
      {value: 'STARTED', label: translateService.instant('STARTED')},
      {value: 'STOPPING', label: translateService.instant('STOPPING')},
      {value: 'STOPPED', label: translateService.instant('STOPPED')},
      {value: 'FAILED', label: translateService.instant('FAILED')},
      {value: 'ABANDONED', label: translateService.instant('ABANDONED')},
      {value: 'UNKNOWN', label: translateService.instant('UNKNOWN')}
    );
  }

  initializeCriteria() {
    this.dataProvider.searchCriteria.criteria = <BatchSearchCriteriaDto>{onlyLastExecution: true};
    this.dataProvider.textSearch = false;
  }

  run() {
    this._router.toRunBatch(this.dataProvider.searchCriteria.criteria.jobName);
  }

  onChangeJobName(job: string) {
    this.dataProvider.searchCriteria.criteria.onlyLastExecution = !job;
  }
}
