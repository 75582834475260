import {ThirdPartyDetailsComponent} from './components/thirdparty-details.component';
import {CompanyThirdPartyAccessGuard} from '../company/_guards/company-third-party-access.guard';

export const ThirdPartyRoutes = [
  {
    path: 'thirdparty-details',
    component: ThirdPartyDetailsComponent,
    canActivate: [CompanyThirdPartyAccessGuard],
  },
  {
    path: 'thirdparty-details/:id',
    component: ThirdPartyDetailsComponent,
    canActivate: [CompanyThirdPartyAccessGuard],
  },
];
