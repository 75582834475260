<details-view [parentComponent]="self" [fixedBlockHidden]="false" [versionListHidden]="true" [objectName]="'User'"
              [title]="'user.details.title'">
  <div class="details-content">
      <user-data *ngIf="(userCompleted && rolesCompleted && groups)" [header]="'User Details'" [user]="user" [roles]="roles" [groups]="groups"></user-data>
  </div>
  <div class="additional-buttons">
    <lifecycle-dropdown-simple *ngIf="user?.id" [service]="userService" [selectedObject]="user"
                               [saveFunc]="onSave.bind(self)" [inProgress]="inProgress">
    </lifecycle-dropdown-simple>
    <button class="bon-btn-success" type="button" [hidden]="!user.id" (click)="generateHistoryReport()" btnIcon="fa-file-text-o" translate>user.details.historyReport</button>
    <button class="bon-btn-success" type="button" [hidden]="!user.id || user.status?.id === UserStatus.ACTIVATED || user.status?.id === UserStatus.DELETED || !user.activationLinkEnabled"
            (click)="sendActivationLink()" btnIcon="fa-file-text-o" translate>user.details.activationLink</button>
  </div>
  <div class="information-header" *ngIf="user">
    <b style="display: inline-block; width: 250px;" class="font-larger"> {{user | username}}</b>
    <b class="id" *ngIf="user.id"> Id: {{user.id}}</b>
  </div>
</details-view>
<confirm-dialog></confirm-dialog>
