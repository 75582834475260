import {Component, Input, ViewChild} from '@angular/core';
import {PolicyInquiryStatus} from '../../../bonding_shared/model/dictionary-ids';
import {
  DictionaryBaseDto,
  FormDialogComponent,
  InquiryService,
  PolicyInquiryCloseReasonDto,
  PolicyInquiryOfferRejectionReasonDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionDto,
  PolicyInquiryVersionSimpleDto,
  RouterService,
  SearchCriteria,
  SearchResult,
  StateTransitionDto,
} from '../../../bonding_shared';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {InquiryCountries} from '../domain/inquiry-countries';
import {Observable} from 'rxjs';

@Component({
  selector: 'inquiry-lifecycle-dropdown',
  templateUrl: 'inquiry-lifecycle-dropdown.component.pug',
})
export class InquiryLifecycleDropdownComponent {
  self = this;

  @Input() set inquiry(inq: PolicyInquiryVersionDto) {
    this._inquiry = inq;
    this.closeForm.reset();
    this.searchIdenticalInquiry();
  }

  @Input() onSave: Function;
  @Input() inProgress;

  closeForm: UntypedFormGroup;
  showCloseErrors = false;
  identicalInquiry: PolicyInquiryVersionSimpleDto = null;
  identicalInquiryInProgress = false;

  get inquiry() {
    return this._inquiry;
  }

  private _inquiry: PolicyInquiryVersionDto;

  constructor(public inquiryService: InquiryService, private routerService: RouterService) {
    this.closeForm = new UntypedFormGroup({
      type: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    });
    this.closeForm.addControl('comment', new UntypedFormControl({value: '', disabled: false}, Validators.required));
  }

  onLifecycle(action: DictionaryBaseDto) {
    const inq = this.inquiry;
    const statusId = inq.status.id;

    const setReasonOnCloseState = (r: PolicyInquiryCloseReasonDto) => {
      if (statusId === PolicyInquiryStatus.POLICY_INQUIRY_CLOSED) {
        inq.closeReason = r;
      }
    };

    if (statusId === PolicyInquiryStatus.POLICY_INQUIRY_CLOSED) {
      const type = this.closeForm.get('type').value;
      if (type && type.id) {
        setReasonOnCloseState(_.assign(<PolicyInquiryOfferRejectionReasonDto>{}, this.closeForm.getRawValue()));
      }
    }

    this.onSave().subscribe(
      () => {},
      () => setReasonOnCloseState(null)
    );
  }

  validCloseInput(): (transition: StateTransitionDto) => () => boolean {
    return (t) => {
      return () => {
        if (this.isShowRegistrationConfirmation(t)) {
          return true;
        }

        if (this.isShowCloseConfirmation(t)) {
          const valid = this.closeForm.valid;
          this.showCloseErrors = !valid;
          return valid;
        }
        return true;
      };
    };
  }

  isShowConfirmation(): (transition: StateTransitionDto) => boolean {
    return (transition: StateTransitionDto) =>
      this.isShowCloseConfirmation(transition) ||
      this.isShowRegistrationConfirmation(transition) ||
      transition.dangerous;
  }

  isShowCloseConfirmation(transition: StateTransitionDto): boolean {
    return transition && transition.newStatus && transition.newStatus.id === PolicyInquiryStatus.POLICY_INQUIRY_CLOSED;
  }

  isShowRegistrationConfirmation(transition: StateTransitionDto): boolean {
    return (
      transition &&
      transition.newStatus &&
      transition.newStatus.id === PolicyInquiryStatus.POLICY_INQUIRY_REGISTERED &&
      this.identicalInquiry !== null
    );
  }

  navigateToIdenticalInquiryDetails() {
    this.routerService.toInquiryDetails(this.identicalInquiry);
  }

  private searchIdenticalInquiry() {
    const inq = this._inquiry;
    if (
      inq &&
      inq.status &&
      inq.status.id === PolicyInquiryStatus.POLICY_INQUIRY &&
      inq.client &&
      inq.client.company &&
      inq.client.company.id &&
      inq.productType
    ) {
      const criteria: SearchCriteria<PolicyInquiryVersionCriteriaDto> =
        BusinessUtils.createPolicyInquiryVersionSearchCriteria();
      criteria.criteria.client.id = inq.client.company.id;
      criteria.criteria.productType = inq.productType;
      criteria.criteria.statuses = [
        PolicyInquiryStatus.POLICY_INQUIRY_REGISTERED,
        PolicyInquiryStatus.POLICY_INQUIRY_ACCEPTED,
      ];

      const nTo0 = (v: number) => (!v ? 0 : v);
      const sumPlannedTO =
        nTo0(inq.domesticInsurable) +
        nTo0(inq.exportInsurable) +
        nTo0(inq.lastYearsTO) +
        nTo0(InquiryCountries.countriesSumPlannedTO(inq, (c) => c.plannedTO));
      if (sumPlannedTO > 0) {
        criteria.criteria.sumPlannedTO = sumPlannedTO;
      }

      this.identicalInquiryInProgress = true;
      this.searchByCriteriaInquiries(criteria).subscribe(
        (inquiries) => {
          this.initIdenticalInquiry(inquiries.result);
          this.identicalInquiryInProgress = false;
        },
        (error) => {
          this.identicalInquiryInProgress = false;
          console.error('identical Inquiry search problem: ', error);
        }
      );
    }
  }

  // cache for 15 minutes
  // @Cacheable({maxCacheCount: 25, maxAge: 900000})
  private searchByCriteriaInquiries(
    criteria: SearchCriteria<PolicyInquiryVersionCriteriaDto>
  ): Observable<SearchResult<PolicyInquiryVersionSimpleDto>> {
    return this.inquiryService.searchByCriteria<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>(
      criteria
    );
  }

  private initIdenticalInquiry(result: PolicyInquiryVersionSimpleDto[]) {
    this.identicalInquiry = null;
    if (result && result.length > 0) {
      const othersInquiries = result.filter((inq) => inq.id !== this._inquiry.id);
      if (othersInquiries.length > 0) {
        this.identicalInquiry = result[0];
      }
    }
  }
}
