export const environment = {
  production: true,
};
export const APP_CONFIG = {
  apiConfig: {
    selectedProfile: 'uat',
    profiles: {
      uat: {
        backendUrlInfix: 'cesar',
        themeColour: 'brown',
        displayName: 'CeSAR UAT',
        helpLink: '/SpecBonding/',
      },
    },
  },
};
