import {DictionaryBaseDto, PolicyInquiryVersionDto, UserGroupBaseDto} from '../../../bonding_shared';
import {InquiryProductType, InquiryProductTypes, UserGroupType} from '../../../bonding_shared/model/dictionary-ids';

export function isType(ofeType: DictionaryBaseDto, typeId: InquiryProductType): boolean {
  return ofeType && ofeType.id === typeId;
}

const USER_GROUPS_TYPES = {
  ALL: [UserGroupType.POLICY_OFFER_VOTING],
  RISK: [UserGroupType.POLICY_OFFER_VOTING, UserGroupType.RISK_POLICY_OFFER_VOTING],
};

export function votingGroupFilter(
  ofeType: DictionaryBaseDto
): (value: UserGroupBaseDto, index: number, array: UserGroupBaseDto[]) => boolean {
  const groupTypes = getUserGroupTypes(ofeType);
  return (group) => group.active && groupTypes.indexOf(group.type.id) > -1;
}

function getUserGroupTypes(ofeType: DictionaryBaseDto): ReadonlyArray<UserGroupType> {
  if (isRiskVoting(ofeType)) {
    return USER_GROUPS_TYPES.RISK;
  }
  return USER_GROUPS_TYPES.ALL;
}

function isRiskVoting(ofeType: DictionaryBaseDto) {
  return isType(ofeType, InquiryProductType.SINGLE_RISK) || isType(ofeType, InquiryProductType.TURNOVER);
}
export function isRegistrationCompletedAvailable(portalMode: boolean, inquiry: PolicyInquiryVersionDto) {
  return portalMode && inquiry && inquiry.salesPerson && !inquiry.limitsRegistrationCompleted;
}
