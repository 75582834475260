import {Component, OnInit, ViewChild} from '@angular/core';
import {AppConfigService, LoggedUserService, RouterService} from '../../bonding_shared';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {BIReportService} from '../../bonding_shared/services/bi-report.service';
import {BiReportListComponent} from './components';
import {ActivatedRoute} from '@angular/router';
import {BiReportGuiService} from './services/bi-report-gui.service';

@Component({
  selector: 'bi-report-search',
  templateUrl: './bi-report-search.component.pug',
})
export class BiReportSearchComponent extends SearchView implements OnInit {
  @ViewChild(BiReportListComponent, {static: true}) reportList: BiReportListComponent;

  constructor(
    private service: BIReportService,
    public router: RouterService,
    private route: ActivatedRoute,
    public guiService: BiReportGuiService,
    private appService: AppConfigService,
    private loggedUserService: LoggedUserService
  ) {
    super();
  }

  get criteria() {
    return this.guiService.dataProvider.searchCriteria.criteria;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((q) => {
      if (q.reportId) {
        this.criteria.id = q.reportId;
      }
    });
  }

  public performInitialSearch(): boolean {
    return true;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}
